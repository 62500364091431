import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Layout from './Layout';
import ModalAlert from './ModalAlert';
import Data from '../modules/Data';

const Home = (props) => {
	const intl = useIntl();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const anrede = intl.formatMessage({ id: 'title.generic' });

	let showChangesWarning = false;
	const oldValidationState = JSON.parse(window.localStorage.getItem('validations'));
	const validationState = Data.validateSections(props.config.data);
	const allSectionsAreValid = Data.areAllSectionsValid(validationState);
	if (oldValidationState) {
		Object.keys(oldValidationState).forEach((key) => {
			if (oldValidationState[key] === true && oldValidationState[key] !== validationState[key]) {
				showChangesWarning = true;
			}
		});
	}
	window.localStorage.setItem('validations', JSON.stringify(validationState));

	useEffect(() => {
		props.onConfigUpdate({ home: '/home' });
		if (showChangesWarning) setTimeout(() => setIsModalOpen(true), 250);
	}, []);

	const handleAlertClose = () => setIsModalOpen(false);

	return (
		<Layout title="app.title" {...props}>
			<div className="content form-offer">
				<div className="content-inner">
					<h1 className="smaller">{`${anrede} ${props.config.data.VORNA}`}</h1>
					<p>
						<FormattedMessage id="home.text"></FormattedMessage>
					</p>
					<div className="home">
						<ul>
							<li>
								<NavLink
									to="/personal-details"
									className={validationState.personalDetailsIsValid ? 'valid' : 'invalid'}
								>
									<FormattedMessage id="home.personal"></FormattedMessage>
								</NavLink>
							</li>
							<li>
								<NavLink to="/partner" className={validationState.partnerIsValid ? 'valid' : 'invalid'}>
									<FormattedMessage id="home.partner"></FormattedMessage>
								</NavLink>
							</li>
							<li>
								<NavLink to="/children" className={validationState.childrenIsValid ? 'valid' : 'invalid'}>
									<FormattedMessage id="home.children"></FormattedMessage>
								</NavLink>
							</li>
							<li>
								<NavLink to="/financial" className={validationState.financialIsValid ? 'valid' : 'invalid'}>
									<FormattedMessage id="home.financial"></FormattedMessage>
								</NavLink>
							</li>
							<li>
								<NavLink to="/uploads" className={validationState.uploadsIsValid ? 'valid' : 'invalid'}>
									<FormattedMessage id="home.uploads"></FormattedMessage>
								</NavLink>
							</li>
							<li>
								<NavLink to="/documents" className={validationState.documentsIsValid ? 'valid' : 'invalid'}>
									<FormattedMessage id="home.documents"></FormattedMessage>
								</NavLink>
							</li>
						</ul>
						<div className="button-group single center">
							<NavLink
								to="/summary"
								className="button"
								tabIndex={!allSectionsAreValid ? '-1' : null}
								disabled={!allSectionsAreValid}
							>
								<FormattedMessage id="home.validate"></FormattedMessage>
							</NavLink>
						</div>
						{false && process.env.DEV && (
							<div>
								<hr />
								<pre>{JSON.stringify(props.config.data, 0, 2)}</pre>
							</div>
						)}
					</div>
				</div>
			</div>
			<ModalAlert
				isOpen={isModalOpen}
				handleAlertClose={handleAlertClose}
				title="home.modal_zusaetzl.title"
				text="home.modal_zusaetzl.text"
			></ModalAlert>
		</Layout>
	);
};

Home.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Home;
