import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { findIndex, padStart } from 'lodash';
import { format } from 'date-fns';
import axios from 'axios';
import ModalConfirm from './ModalConfirm';
import Layout from './Layout';
import Data from '../modules/Data';
import Schema from '../modules/Schema';
import { sleep } from '../modules/Utils';

const Summary = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { data } = props.config;
	const state = Data.validateSections(data);
	const schemaUploads = Schema.load(Schema.uploads, data);

	if (!Data.areAllSectionsValid(state)) {
		navigate('/home', { replace: true });
	}

	useEffect(() => {
		const newConfig = props.config;
		newConfig.home = '/summary';
		props.onConfigUpdate(newConfig);
	}, []);

	// console.log(props.config.data);

	const formatAHV = (string) =>
		`${string.substr(0, 3)}.${string.substr(3, 4)}.${string.substr(7, 4)}.${string.substr(11, 2)}`;

	const formatZemis = (string) =>
		string === '' ? '-' : `${string.substr(0, 3)}-${string.substr(3, 3)}-${string.substr(6, 3)}-${string.substr(9, 1)}`;

	const formatIban = (string) =>
		`${string.substr(0, 4)} ${string.substr(4, 4)} ${string.substr(8, 4)} ${string.substr(12, 4)} ${string.substr(
			16,
			4,
		)} ${string.substr(20, 1)}`;

	const handleConfirmOpen = () => setIsConfirmOpen(true);

	const handleConfirmOk = () => {
		// delete unused documents
		const toDelete = [];
		for (let index = 1; index < 17; index += 1) {
			const key = `ZDK${padStart(index, 2, '0')}`;
			if (data[key].ZDOKN !== '' && !schemaUploads.fields[key]) {
				toDelete.push({ ZDOKART: key, ZDOKU: '', ZDOKN: '' });
			}
		}
		if (toDelete.length > 0) {
			axios.post(`${process.env.APP_SERVER}`, { action: 'delete', ZDOKU: toDelete });
		}

		// finalize submission
		setIsLoading(true);
		sleep(toDelete.length > 0 ? 100 : 0).then(() => {
			axios.post(`${process.env.APP_SERVER}`, { action: 'submit' }).then((result) => {
				// if (process.env.DEV) console.log(result.data);
				if (result.data.result) {
					const newConfig = props.config;
					newConfig.isCompleted = true;
					newConfig.isAuthenticated = false;
					props.onConfigUpdate(newConfig);
					navigate('/confirmed', { replace: true });
				}
			});
		});
	};

	const handleConfirmCancel = () => setIsConfirmOpen(false);

	const label = (id) => {
		const message = intl.formatMessage({ id });
		return typeof message === 'string' ? message.replace('*', '') : id;
	};

	return (
		<Layout title="app.title" {...props}>
			<div className="content form-offer">
				<div className="content-inner">
					<h1 className="smaller">
						<FormattedMessage id="summary.title"></FormattedMessage>
					</h1>
					<p>
						<FormattedMessage id="summary.text"></FormattedMessage>
					</p>
					<br></br>
					<form onSubmit={() => false}>
						<h3>
							<FormattedMessage id="home.personal"></FormattedMessage>
						</h3>
						<table className="summary">
							<tbody>
								<tr>
									<th>{label('forms.anrede')}</th>
									<td>{intl.messages.ANRED[findIndex(intl.messages.ANRED, { key: data.ANRED })].value}</td>
								</tr>
								<tr>
									<th>{label('forms.vorname')}</th>
									<td>{data.VORNA}</td>
								</tr>
								<tr>
									<th>{label('forms.nachname')}</th>
									<td>{data.NACHN}</td>
								</tr>
								{data.NAME2 !== '' && (
									<tr>
										<th>{label('forms.geburtsname')}</th>
										<td>{data.NAME2}</td>
									</tr>
								)}
								<tr>
									<th>{label('forms.geburtsdatum')}</th>
									<td>{format(new Date(data.GBDAT), 'dd.MM.yyyy')}</td>
								</tr>
								<tr>
									<th>&nbsp;</th>
									<td></td>
								</tr>
								<tr>
									<th>{label('section.wohnadr')}</th>
									<td></td>
								</tr>
								<tr>
									<th>{label('forms.strasse')}</th>
									<td>{data.STRAS_2}</td>
								</tr>
								<tr>
									<th>{label('forms.plzort')}</th>
									<td>{`${data.PSTLZ_2} ${data.ORT01_2}`}</td>
								</tr>
								<tr>
									<th>{label('forms.land')}</th>
									<td>{intl.messages.LANDX[findIndex(intl.messages.LANDX, { key: data.LAND1_2 })].value}</td>
								</tr>
								{data.STATE_2 !== '' && (
									<tr>
										<th>{label('forms.kanton')}</th>
										<td>{intl.messages.KANTO[findIndex(intl.messages.KANTO, { key: data.STATE_2 })].value}</td>
									</tr>
								)}
								{data.STRAS_1 !== '' && (
									<>
										<tr>
											<th>&nbsp;</th>
											<td></td>
										</tr>
										<tr>
											<th>{label('section.korrspndzadr')}</th>
											<td></td>
										</tr>
										<tr>
											<th>{label('forms.strasse')}</th>
											<td>{data.STRAS_1}</td>
										</tr>
										<tr>
											<th>{label('forms.plzort')}</th>
											<td>{`${data.PSTLZ_1} ${data.ORT01_1}`}</td>
										</tr>
										<tr>
											<th>{label('forms.land')}</th>
											<td>{intl.messages.LANDX[findIndex(intl.messages.LANDX, { key: data.LAND1_1 })].value}</td>
										</tr>
										{data.STATE_1 !== '' && (
											<tr>
												<th>{label('forms.kanton')}</th>
												<td>{intl.messages.KANTO[findIndex(intl.messages.KANTO, { key: data.STATE_1 })].value}</td>
											</tr>
										)}
									</>
								)}
								<tr>
									<th>&nbsp;</th>
									<td></td>
								</tr>
								<tr>
									<th>{label('forms.nationalitaet_p')}</th>
									<td>{intl.messages.LANDX[findIndex(intl.messages.LANDX, { key: data.NATIO })].value}</td>
								</tr>
								{data.NATIO !== 'CH' && (
									<>
										<tr>
											<th>{label('forms.aufenhaltsbew')}</th>
											<td>{intl.messages.ASTAT[findIndex(intl.messages.ASTAT, { key: data.ASTAT })].value}</td>
										</tr>
										{data.ASTAT === 'G' && (
											<tr>
												<th>{label('forms.rueckkehr')}</th>
												<td>{intl.messages.ZRUEC[findIndex(intl.messages.ZRUEC, { key: data.ZRUEC })].value}</td>
											</tr>
										)}
										{data.ASTAT !== '1' && (
											<>
												<tr>
													<th>{label('forms.zemis')}</th>
													<td>{formatZemis(data.BEWNR)}</td>
												</tr>
												{data.ABLAD !== '' && data.ABLAD !== '0000-00-00' && (
													<tr>
														<th>{label('forms.zemis_gueltig')}</th>
														<td>{format(new Date(data.ABLAD), 'dd.MM.yyyy')}</td>
													</tr>
												)}
											</>
										)}
										<tr>
											<th>{label('forms.konfession')}</th>
											<td>{intl.messages.KONFE[findIndex(intl.messages.KONFE, { key: data.KONFE })].value}</td>
										</tr>
									</>
								)}
								{data.Z_SCI_ADPV === '' && (
									<>
										<tr>
											<th>&nbsp;</th>
											<td></td>
										</tr>
										<tr>
											<th>{label('forms.verbaende')}</th>
											<td>
												<div className="check"></div>
											</td>
										</tr>
									</>
								)}
							</tbody>
						</table>
						<div className="button-group single">
							<NavLink to="/personal-details" className="button secondary no-print">
								<FormattedMessage id="button.edit"></FormattedMessage>
							</NavLink>
						</div>
						<hr />
						<h3>
							<FormattedMessage id="home.partner"></FormattedMessage>
						</h3>
						<table className="summary">
							<tbody>
								<tr>
									<th>{label('forms.zivilstand')}</th>
									<td>{intl.messages.FAMST[findIndex(intl.messages.FAMST, { key: data.FAMST })].value}</td>
								</tr>
								{data.FAMST !== '0' && (
									<tr>
										<th>{label('forms.zivilstand_seit')}</th>
										<td>{format(new Date(data.FAMDT), 'dd.MM.yyyy')}</td>
									</tr>
								)}
								{(data.FAMST === '1' || data.FAMST === '5') && (
									<>
										<tr>
											<th>&nbsp;</th>
											<td></td>
										</tr>
										<tr>
											<th>{label('summary.partner')}</th>
											<td></td>
										</tr>
										<tr>
											<th>{label('forms.anrede')}</th>
											<td>{intl.messages.ANRED[findIndex(intl.messages.ANRED, { key: data.ZPART_FASEX })].value}</td>
										</tr>
										<tr>
											<th>{label('forms.vorname')}</th>
											<td>{data.ZPART_FAVOR}</td>
										</tr>
										<tr>
											<th>{label('forms.nachname')}</th>
											<td>{data.ZPART_FANAM}</td>
										</tr>
										{data.NAME2 !== '' && (
											<tr>
												<th>{label('forms.geburtsname')}</th>
												<td>{data.ZPART_FGBNA}</td>
											</tr>
										)}
										<tr>
											<th>{label('forms.geburtsdatum')}</th>
											<td>{format(new Date(data.ZPART_FGBDT), 'dd.MM.yyyy')}</td>
										</tr>
										{data.STRAS_9 !== '' ? (
											<>
												<tr>
													<th>{label('forms.strasse')}</th>
													<td>{data.STRAS_9}</td>
												</tr>
												<tr>
													<th>{label('forms.plzort')}</th>
													<td>{`${data.PSTLZ_9} ${data.ORT01_9}`}</td>
												</tr>
												<tr>
													<th>{label('forms.land')}</th>
													<td>{intl.messages.LANDX[findIndex(intl.messages.LANDX, { key: data.LAND1_9 })].value}</td>
												</tr>
											</>
										) : (
											<tr>
												<th>{label('section.wohnadr')}</th>
												<td>{label('summary.identisch')}</td>
											</tr>
										)}
										<tr>
											<th>{label('forms.nationalitaet_p')}</th>
											<td>{intl.messages.LANDX[findIndex(intl.messages.LANDX, { key: data.ZPART_FANAT })].value}</td>
										</tr>
										{data.ZPART_FANAT.length > 1 && data.ZPART_FANAT !== 'CH' && (
											<>
												<tr>
													<th>{label('forms.aufenhaltsbew')}</th>
													<td>
														{intl.messages.ASTAT[findIndex(intl.messages.ASTAT, { key: data.ZPART_ZAUFB })].value}
													</td>
												</tr>
												<tr>
													<th>{label('section.quellensteuer')}</th>
													<td>{data.ZPART_ZQSTE === 'X' ? label('forms.label.yes') : label('forms.label.no')}</td>
												</tr>
												<tr>
													<th>&nbsp;</th>
													<td></td>
												</tr>
												<tr>
													<th>{label('summary.beschaeftigung_p')}</th>
													<td></td>
												</tr>
												{data.ZPART_EMPYN === 'X' && (
													<>
														<tr>
															<th>{label('forms.arbeitgeber')}</th>
															<td>{data.ZPART_ZARBG}</td>
														</tr>
														<tr>
															<th>{label('forms.arbeitsort')}</th>
															<td>{data.ZPART_ZARBO}</td>
														</tr>
														<tr>
															<th>{label('forms.erwerbsart')}</th>
															<td>
																{intl.messages.ZEINA[findIndex(intl.messages.ZEINA, { key: data.ZPART_ZEINA })].value}
															</td>
														</tr>
														<tr>
															<th>{label('forms.beginn_taet')}</th>
															<td>{format(new Date(data.ZPART_PAYBEGDA), 'dd.MM.yyyy')}</td>
														</tr>
														{data.ZPART_PAYENDDA !== '' && data.ZPART_PAYENDDA !== '0000-00-00' && (
															<tr>
																<th>{label('forms.ende_taet')}</th>
																<td>{format(new Date(data.ZPART_PAYENDDA), 'dd.MM.yyyy')}</td>
															</tr>
														)}
														{data.ZPART_NAHVN !== '' && (
															<tr>
																<th>{label('forms.sozvrsnr_partner')}</th>
																<td>{formatAHV(data.ZPART_NAHVN)}</td>
															</tr>
														)}
													</>
												)}
												<tr>
													<th>{label('section.ersatzeinkommen')}</th>
													<td>{data.ZPART_ZERSA === 'X' ? label('forms.label.yes') : label('forms.label.no')}</td>
												</tr>
												{data.ZPART_ZERSA === 'X' && data.ZPART_ZERSD !== '' && data.ZPART_ZERSD !== '0000-00-00' && (
													<tr>
														<th>{label('forms.ersatz_seit')}</th>
														<td>{format(new Date(data.ZPART_ZERSD), 'dd.MM.yyyy')}</td>
													</tr>
												)}
											</>
										)}
									</>
								)}
							</tbody>
						</table>
						<div className="button-group single">
							<NavLink to="/partner" className="button secondary no-print">
								<FormattedMessage id="button.edit"></FormattedMessage>
							</NavLink>
						</div>
						<hr />
						<h3>
							<FormattedMessage id="home.children"></FormattedMessage>
						</h3>
						<table className="summary">
							{data.ZKIND.length === 0 && (
								<tbody>
									<tr>
										<th>{label('forms.anzahlkinder')}</th>
										<td>{label('summary.keinekinder')}</td>
									</tr>
								</tbody>
							)}
							{data.ZKIND.map((kind, index) => (
								<tbody key={index}>
									{index > 0 && (
										<tr>
											<th>&nbsp;</th>
											<td></td>
										</tr>
									)}
									<tr>
										<th>
											<FormattedMessage id="section.kind" values={{ id: index + 1 }} />
										</th>
										<td></td>
									</tr>
									<tr>
										<th>{label('forms.vorname')}</th>
										<td>{kind.FAVOR}</td>
									</tr>
									<tr>
										<th>{label('forms.nachname')}</th>
										<td>{kind.FANAM}</td>
									</tr>
									<tr>
										<th>{label('forms.geschlecht')}</th>
										<td>{intl.messages.GESCH[findIndex(intl.messages.GESCH, { key: kind.FASEX })].value}</td>
									</tr>
									<tr>
										<th>{label('forms.geburtsdatum')}</th>
										<td>{format(new Date(kind.FGBDT), 'dd.MM.yyyy')}</td>
									</tr>
									<tr>
										<th>{label('forms.kind_in_ch')}</th>
										<td>{kind.ZINCH === 'X' ? label('forms.label.yes') : label('forms.label.no')}</td>
									</tr>
									{kind.ZINCH === 'X' && (
										<tr>
											<th>{label('forms.sozversnr_kind')}</th>
											<td>{formatAHV(kind.NAHVN)}</td>
										</tr>
									)}
									<tr>
										<th>{label('forms.unterbringung')}</th>
										<td>{intl.messages.KDUTB[findIndex(intl.messages.KDUTB, { key: kind.KDUTB })].value}</td>
									</tr>
									<tr>
										<th>{label('forms.kindsverhaeltnis')}</th>
										<td>{intl.messages.KDSVH[findIndex(intl.messages.KDSVH, { key: kind.KDSVH })].value}</td>
									</tr>
								</tbody>
							))}
							{data.ZKIZU === 'X' && (
								<tbody>
									<tr>
										<th>&nbsp;</th>
										<td></td>
									</tr>
									<tr>
										<th>{label('forms.antragfamzulagen')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								</tbody>
							)}
						</table>
						<div className="button-group single">
							<NavLink to="/children" className="button secondary no-print">
								<FormattedMessage id="button.edit"></FormattedMessage>
							</NavLink>
						</div>
						<hr />
						<h3>
							<FormattedMessage id="home.financial"></FormattedMessage>
						</h3>
						<table className="summary">
							<tbody>
								{data.NAHVN !== '' && (
									<tr>
										<th>{label('forms.sozversnr_kind')}</th>
										<td>{formatAHV(data.NAHVN)}</td>
									</tr>
								)}
								{(data.PERSK === '20' || data.PERSK === '35') && (
									<tr>
										<th>{label('section.lohnzahlung')}</th>
										<td>{data.ZANZL === '01' ? label('forms.basislohn_01') : label('forms.basislohn_02')}</td>
									</tr>
								)}
								<tr>
									<th>{label('forms.chkonto')}</th>
									<td>{data.ZBANK === 'X' ? label('forms.label.yes') : label('forms.label.no')}</td>
								</tr>
								{data.IBAN !== '' && (
									<tr>
										<th>{label('forms.iban')}</th>
										<td>{formatIban(data.IBAN)}</td>
									</tr>
								)}
								{((data.ZGSBB === '' && (data.PERSK === '20' || data.PERSK === '35')) || data.ZGSBB === 'X') && (
									<>
										<tr>
											<th>&nbsp;</th>
											<td></td>
										</tr>
										<tr>
											<th>{label('section.sbb')}</th>
											<td></td>
										</tr>
									</>
								)}
								{data.ZGSBB === 'X' && (
									<tr>
										<th>{label('forms.sbb_kdnr')}</th>
										<td>{formatZemis(data.LOBNR)}</td>
									</tr>
								)}
								{data.ZGSBB === '' && (data.PERSK === '20' || data.PERSK === '35') && (
									<tr>
										<th>{label('forms.sbb_verguetung')}</th>
										<td>{intl.messages.ZFSBB[findIndex(intl.messages.ZFSBB, { key: data.ZFSBB })].value}</td>
									</tr>
								)}
								<tr>
									<th>&nbsp;</th>
									<td></td>
								</tr>
								<tr>
									<th>{label('section.nebenerwerb')}</th>
									<td>{data.NBESC === '1' ? label('forms.label.yes') : label('forms.label.no')}</td>
								</tr>
								{data.NBESC === '1' && (
									<>
										<tr>
											<th>{label('forms.nebenerwerb_art')}</th>
											<td>{data.TAETE}</td>
										</tr>
										<tr>
											<th>{label('summary.umfang')}</th>
											<td>{`${data.WOSTD} ${label('summary.stunden')}`}</td>
										</tr>
									</>
								)}
							</tbody>
						</table>
						<div className="button-group single">
							<NavLink to="/financial" className="button secondary no-print">
								<FormattedMessage id="button.edit"></FormattedMessage>
							</NavLink>
						</div>
						<hr />
						<h3>
							<FormattedMessage id="home.uploads"></FormattedMessage>
						</h3>
						<table className="summary">
							<tbody>
								<tr>
									<th>{label('forms.vertrag')}</th>
									<td>{data.ZVERP === 'X' ? label('summary.per_post') : <div className="check"></div>}</td>
								</tr>
								{data.ZVERP === 'X' && (
									<tr>
										<th>{label('forms.vertrag_sendedatum')}</th>
										<td>{format(new Date(data.ZVERD), 'dd.MM.yyyy')}</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK02 && (
									<tr>
										<th>{label('forms.passfoto')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK11 && (
									<tr>
										<th>{label('forms.id_pass')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK12 && (
									<tr>
										<th>{label('forms.auslaenderausw')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK13 && (
									<tr>
										<th>{label('forms.pass_partner')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK06 && (
									<tr>
										<th>{label('forms.nda')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK04 && (
									<tr>
										<th>{label('forms.strafregister')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK05 && (
									<tr>
										<th>{label('forms.betreibungen')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK07 && (
									<tr>
										<th>{label('forms.zeitautonomie')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK03 && (
									<tr>
										<th>{label('forms.privatauto')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK14 && (
									<tr>
										<th>{label('forms.eheurkunde')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK15 && (
									<tr>
										<th>{label('forms.familienbuchlein')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK08 && (
									<tr>
										<th>{label('forms.ansaessigkeit')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK16 && (
									<tr>
										<th>{label('forms.grenzfragen')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
								{schemaUploads.fields.ZDK10 && (
									<tr>
										<th>{label('forms.declarationp')}</th>
										<td>
											<div className="check"></div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
						<div className="button-group single">
							<NavLink to="/uploads" className="button secondary no-print">
								<FormattedMessage id="button.edit"></FormattedMessage>
							</NavLink>
						</div>
						<hr />
						<h3>
							<FormattedMessage id="home.documents"></FormattedMessage>
						</h3>
						<table className="summary">
							<tbody>
								<tr>
									<th>{label('forms.beilagen')}</th>
									<td>
										<div className="check"></div>
									</td>
								</tr>
							</tbody>
						</table>
						<div className="button-group single">
							<NavLink to="/documents" className="button secondary no-print">
								<FormattedMessage id="button.edit"></FormattedMessage>
							</NavLink>
						</div>
						<hr />
						<div className="button-group">
							<NavLink to="/home" className="button secondary">
								<FormattedMessage id="button.cancel"></FormattedMessage>
							</NavLink>
							<button type="button" className="button" onClick={handleConfirmOpen}>
								<FormattedMessage id="button.submit"></FormattedMessage>
							</button>
						</div>
					</form>
				</div>
			</div>
			<ModalConfirm
				isOpen={isConfirmOpen}
				isLoading={isLoading}
				handleConfirmOk={handleConfirmOk}
				handleConfirmCancel={handleConfirmCancel}
				title="summary.modal_sure.title"
				text="summary.modal_sure.text"
			/>
		</Layout>
	);
};

Summary.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Summary;
