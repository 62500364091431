import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import Input from '../components/Input';
import InputFile from '../components/InputFile';
import InputRadio from '../components/InputRadio';
import InputRadioGroup from '../components/InputRadioGroup';
import Layout from './Layout';
import ErrorFocus from '../components/ErrorFocus';
import HTMLMessage from '../components/HTMLMessage';
import Data from '../modules/Data';
import Schema from '../modules/Schema';

const Uploads = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const formikFormRef = useRef();
	const schema = Schema.load(Schema.uploads, props.config.data);
	const initialValues = schema.cast(Data.dataToValues(Data.uploads, props.config.data));
	const lastschriftenLink = props.config.data.ZDKQS.LINK[intl.locale.toUpperCase()];
	const grenzgengerLink = props.config.data.ZDKGG.LINK[intl.locale.toUpperCase()];

	const saveForm = (values) => {
		const { data, home } = props.config;
		Data.saveSection(Data.uploads, values, data, navigate, home);
	};

	const renderFormik = () => {
		const { fields } = schema;
		console.log(schema);

		return (
			<Formik
				isInitialValid
				initialValues={initialValues}
				validationSchema={schema}
				innerRef={formikFormRef}
				onSubmit={saveForm}
			>
				{({ values, errors, touched }) => (
					<Form>
						<div className="form-header">
							<h1 className="smaller">
								<FormattedMessage id="home.uploads"></FormattedMessage>
							</h1>
							<button onClick={() => navigate(-1)} type="button" className="close-button">
								&nbsp;
							</button>
						</div>
						<h3>
							<FormattedMessage id="forms.vertrag"></FormattedMessage>
						</h3>
						<HTMLMessage id="forms.vertrag_xplain"></HTMLMessage>
						<InputRadioGroup
							label="forms.vertrag_perpost"
							name="ZVERP"
							values={values}
							errors={errors}
							touched={touched}
						>
							<Field component={InputRadio} name="ZVERP" label="forms.label.yes" value="X" valueFormat="string" />
							<Field component={InputRadio} name="ZVERP" label="forms.label.no" value="O" valueFormat="string" />
						</InputRadioGroup>
						{values.ZVERP === 'X' && (
							<Field
								component={Input}
								type="date"
								name="ZVERD"
								label="forms.vertrag_sendedatum"
								placeholder="forms.datum_placeholder"
							/>
						)}
						{values.ZVERP === 'O' && (
							<Field component={InputFile} name="ZDK01" label="forms.documents_caption" format="pdf" />
						)}

						{fields.ZDK02 && (
							<>
								<h3>
									<FormattedMessage id="forms.passfoto"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.passfoto_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK02" label="forms.passfoto_caption" format="jpg" />
							</>
						)}

						{fields.ZDK11 && (
							<>
								<h3>
									<FormattedMessage id="forms.id_pass"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.id_pass_xplain"></HTMLMessage>
								<Field
									component={InputFile}
									name="ZDK11"
									secondImage="ZDK11B"
									label="forms.id_pass_caption"
									format="pdf"
								/>
							</>
						)}

						{fields.ZDK12 && (
							<>
								<h3>
									<FormattedMessage id="forms.auslaenderausw"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.auslaenderausw_xplain"></HTMLMessage>
								<Field
									component={InputFile}
									name="ZDK12"
									secondImage="ZDK12B"
									label="forms.auslaenderausw_caption"
									format="pdf"
								/>
							</>
						)}

						{fields.ZDK13 && (
							<>
								<h3>
									<FormattedMessage id="forms.pass_partner"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.pass_partner_xplain"></HTMLMessage>
								<Field
									component={InputFile}
									name="ZDK13"
									secondImage="ZDK13B"
									label="forms.pass_partner_caption"
									format="pdf"
								/>
							</>
						)}

						{fields.ZDK06 && (
							<>
								<h3>
									<FormattedMessage id="forms.nda"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.nda_xplain"></HTMLMessage>
								<Field
									component={InputFile}
									name="ZDK06"
									secondImage="ZDK06B"
									label="forms.documents_caption"
									format="pdf"
								/>
							</>
						)}

						{fields.ZDK04 && (
							<>
								<h3>
									<FormattedMessage id="forms.strafregister"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.strafregister_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK04" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK05 && (
							<>
								<h3>
									<FormattedMessage id="forms.betreibungen"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.betreibungen_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK05" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK07 && (
							<>
								<h3>
									<FormattedMessage id="forms.zeitautonomie"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.zeitautonomie_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK07" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK03 && (
							<>
								<h3>
									<FormattedMessage id="forms.privatauto"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.privatauto_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK03" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK14 && (
							<>
								<h3>
									<FormattedMessage id="forms.eheurkunde"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.eheurkunde_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK14" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK15 && (
							<>
								<h3>
									<FormattedMessage id="forms.familienbuchlein"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.familienbuchlein_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK15" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK18 && (
							<>
								<h3>
									<FormattedMessage id="forms.scheidungsurkunde"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.scheidungsurkunde_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK18" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK08 && (
							<>
								<h3>
									<FormattedMessage id="forms.ansaessigkeit"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.ansaessigkeit_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK08" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK16 && (
							<>
								<h3>
									<FormattedMessage id="forms.grenzfragen"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.grenzfragen_xplain" values={{ link: grenzgengerLink }}></HTMLMessage>
								<Field component={InputFile} name="ZDK16" label="forms.grenzfragen_caption" format="pdf" />
							</>
						)}

						{fields.ZDK10 && (
							<>
								<h3>
									<FormattedMessage id="forms.declarationp"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.declarationp_xplain" values={{ link: lastschriftenLink }}></HTMLMessage>
								<Field component={InputFile} name="ZDK10" label="forms.documents_caption" format="pdf" />
							</>
						)}

						{fields.ZDK09 && (
							<>
								<h3>
									<FormattedMessage id="forms.frz_steuerwhnstz"></FormattedMessage>
								</h3>
								<HTMLMessage id="forms.frz_steuerwhnstz_xplain"></HTMLMessage>
								<Field component={InputFile} name="ZDK09" label="forms.documents_caption" format="pdf" />
							</>
						)}

						<ErrorFocus />
						<div className="button-group move-down">
							<button type="button" className="button secondary" onClick={() => navigate(-1)}>
								<FormattedMessage id="button.cancel"></FormattedMessage>
							</button>
							<button type="submit" className="button">
								<FormattedMessage id="button.save"></FormattedMessage>
							</button>
						</div>
						<div className="required">
							<FormattedMessage id="forms.required_fields"></FormattedMessage>
						</div>
						{false && process.env.DEV && (
							<div>
								<hr />
								<pre>{JSON.stringify(values, 0, 2)}</pre>
								<pre>{JSON.stringify(props.config.data, 0, 2)}</pre>
							</div>
						)}
					</Form>
				)}
			</Formik>
		);
	};

	return (
		<Layout title="app.title" {...props}>
			<div className="content form">{renderFormik()}</div>
		</Layout>
	);
};

Uploads.propTypes = {
	config: PropTypes.object,
};

export default Uploads;
