import { floor, divide, subtract } from 'lodash';

export function calculateAge(date, start) {
	const now = start ? new Date(start) : new Date();
	now.setHours(23);
	now.setMinutes(59);
	now.setSeconds(59);
	now.setMilliseconds(999);
	// console.log(divide(subtract(now, new Date(date)), 31557600000));
	// console.log(new Date(date));
	const age = date === '' || date === '0000-00-00' ? 0 : floor(divide(subtract(now, new Date(date)), 31557600000));
	// console.log('calculateAge', date, start, age);
	return age;
}

export function nextStartDate() {
	// console.log('data.ZEINTR is calculated');
	const today = new Date();
	const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
	const nextFirstDayOfMonth = nextMonth.getDate();
	const result = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), nextFirstDayOfMonth);
	const yyyy = result.getFullYear().toString().padStart(4, '0');
	const mm = (result.getMonth() + 1).toString().padStart(2, '0');
	const dd = result.getDate().toString().padStart(2, '0');
	return `${yyyy}-${mm}-${dd}`;
}

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const validateFile = (file) => file.ZDOKN !== '' && file.ZDOKN !== undefined;

export const validateIBAN = (value) => {
	// keep only alphanumeric characters
	const iban = String(value)
		.toUpperCase()
		.replace(/[^A-Z0-9]/g, '');

	// match and capture (1) the country code, (2) the check digits, and (3) the rest
	const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

	// check syntax and length
	const lengthCH = 21;
	if (!code || iban.length !== lengthCH || (code[1] !== 'CH' && code[1] !== 'LI')) {
		return false;
	}

	// rearrange country code and check digits, and convert chars to ints
	const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => letter.charCodeAt(0) - 55);
	if (!digits) {
		return false;
	}

	let checksum = digits.slice(0, 2);
	for (let offset = 2; offset < digits.length; offset += 7) {
		const fragment = String(checksum) + digits.substring(offset, offset + 7);
		checksum = parseInt(fragment, 10) % 97;
	}

	return checksum === 1;
};

export const validateIBANnotQR = (value) => {
	if (!validateIBAN(value)) {
		return false;
	}
	const checkPart = parseInt(value.substring(4, 9), 10);
	return checkPart < 30000 || checkPart > 31999;
};

export const validateAHV = (value) => {
	if (!value || value === '') return true;

	const ahvn13str = value.replace(/\D/g, '');
	if (ahvn13str.length !== 13) return false;

	let ahvnTemp = ahvn13str.substr(0, ahvn13str.length - 1);
	ahvnTemp = ahvnTemp.split('.').join('').split('').reverse().join('');

	let sum = 0;
	for (let i = 0; i < ahvnTemp.length; i += 1) {
		const add = i % 2 === 0 ? ahvnTemp[i] * 3 : ahvnTemp[i] * 1;
		sum += add;
	}

	const checksum = Math.ceil(sum / 10) * 10 - sum;
	const checknumber = parseInt(ahvn13str[ahvn13str.length - 1], 10);
	if (checksum !== checknumber) {
		return false;
	}

	return true;
};

export const prettyPrintFilesize = (bytes, units = ['B', 'KB', 'MB', 'GB', 'TB']) => {
	if (bytes === 0) {
		return '0 B';
	}
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	const size = Math.floor((bytes / 1024 ** i) * 10) / 10;
	return `${size} ${units[i]}`;
};
