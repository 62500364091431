import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Layout from './Layout';
import Video from '../components/Video';

const Confirmed = ({ config, onConfigUpdate }) => (
	<Layout title="app.title" config={config} onConfigUpdate={onConfigUpdate}>
		<div className="content video">
			<div className="content-inner">
				<h1 className="smaller">
					<FormattedMessage id="confirmed.title"></FormattedMessage>
				</h1>
				<p className="medium">
					<FormattedMessage id="confirmed.text"></FormattedMessage>
				</p>
				<p>
					<a href="mailto:hr.advisory@swisscom.com">hr.advisory@swisscom.com</a>
				</p>
				<Video
					src="video.welcome.src"
					image="video.welcome.image"
					textTitle="video.ready"
					textPlay="video.show_movie"
				></Video>
			</div>
		</div>
	</Layout>
);

Confirmed.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Confirmed;
