import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from './Layout';

const NotFoundPage = (props) => (
	<Layout title="app.title" {...props}>
		<div className="content">
			<div className="content-inner">
				<h1 className="smaller">
					<FormattedMessage id="app.not-found.title"></FormattedMessage>
				</h1>
				<p>
					<FormattedMessage id="app.not-found.text"></FormattedMessage>
				</p>
				<p>
					<a href="mailto:hr.advisory@swisscom.com">hr.advisory@swisscom.com</a>
				</p>
			</div>
		</div>
	</Layout>
);

export default NotFoundPage;
