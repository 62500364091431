import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Layout from './Layout';
import Input from '../components/Input';
import ServerError from './ServerError';
import LoaderSmall from '../components/LoaderSmall';
import ErrorFocus from '../components/ErrorFocus';
import Data from '../modules/Data';
import { sleep } from '../modules/Utils';
import HTMLMessage from '../components/HTMLMessage';

const Login = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const { config, onConfigUpdate, msisdnTruncated } = props;
	const mobileOs = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
	const formikFormRef = useRef();
	const navigate = useNavigate();

	const formSchema = Yup.object().shape({
		token: Yup.string()
			.ensure('login.validation_error')
			.required('login.validation_error')
			.min(6, 'login.validation_error'),
	});

	const initialValues = {
		token: '',
	};

	const login = (values, formik) => {
		axios
			.post(`${process.env.APP_SERVER}`, {
				action: 'token',
				token: values.token,
			})
			.then((response) => {
				// if (process.env.DEV) console.log(response.data);
				if (response.data.result && response.data.page && response.data.authenticated) {
					props.onConfigUpdate({ isAuthenticated: true, data: Data.init(response.data.data) });
					navigate(`/${response.data.page}`, { replace: true });
				} else {
					formik.setFieldError('token', 'forms.networking_generic');
					const el = document.querySelector(`input[name=token]`);
					if (el) el.focus();
				}
			});
	};

	const resendCode = () => {
		setIsLoading(true);

		formikFormRef.current.setFieldTouched('token', false);
		const startTime = new Date().getTime();
		const delayArtificially = 1000;
		axios
			.post(`${process.env.APP_SERVER}`, { action: 'resend-sms' })
			.then((response) => {
				sleep(delayArtificially - (new Date().getTime() - startTime)).then(() => {
					document.querySelector(`[name=token]`).focus();
					if (response.data.error !== 0) {
						if (response.data.error === -100) {
							setIsBlocked(true);
						}
					}
					setIsLoading(false);
				});
			})
			.catch((error) => {
				if (process.env.DEV) console.log(error);
				setIsLoading(false);
			});
	};

	const renderFormik = () => (
		<Formik
			isInitialValid
			initialValues={initialValues}
			validationSchema={formSchema}
			innerRef={formikFormRef}
			onSubmit={login}
		>
			{({ isSubmitting, isValidating, values, setFieldValue, errors, touched, ...rest }) => (
				<Form>
					<Field
						component={Input}
						type={mobileOs ? 'number' : 'text'}
						pattern="\d*"
						name="token"
						label="login.label"
						hideErrors
						placeholder="login.placeholder"
						autoComplete="one-time-code"
						onChange={(e) => {
							const { value } = e.target;
							const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
							if (value === '' || (value.length <= 6 && regex.test(value.toString()))) {
								setFieldValue('token', value);
							}
						}}
					/>
					<ErrorFocus />
					{touched.token && errors.token && (
						<div className="message">
							<FormattedMessage id="login.validation_error"></FormattedMessage>
						</div>
					)}
					{props.smsMock === true && (
						<div className="message navy">
							<FormattedMessage id="login.sms_mock"></FormattedMessage>
						</div>
					)}
					<div className="button-group single">
						<button type="submit" className="button">
							<FormattedMessage id="button.login"></FormattedMessage>
						</button>
					</div>
					{false && process.env.DEV && (
						<div>
							<hr />
							<pre>{JSON.stringify(values, 0, 2)}</pre>
							<pre>{JSON.stringify(rest.errors, 0, 2)}</pre>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);

	const renderReload = () => {
		if (isLoading) {
			return <LoaderSmall></LoaderSmall>;
		}

		return (
			<button
				className="link"
				type="button"
				onClick={() => {
					resendCode();
				}}
			>
				<FormattedMessage id="login.code_resend"></FormattedMessage>
			</button>
		);
	};

	if (isBlocked) {
		const error = { error: -100 };
		return <ServerError error={error} config={props.config} onConfigUpdate={props.onConfigUpdate} />;
	}

	return (
		<Layout title="app.title" config={config} onConfigUpdate={onConfigUpdate}>
			<div className="content">
				<div className="content-inner">
					<h1>
						<FormattedMessage id="login.title"></FormattedMessage>
					</h1>
					<div className="row">
						<div className="content-left">
							<video
								autoPlay
								playsInline
								muted
								loop
								src="/videos/201009_video-onboarding-txt-links-760x680.mp4"
							></video>
						</div>
						<div className="content-right">
							<h3>
								<FormattedMessage id="login.subtitle"></FormattedMessage>
							</h3>
							<p>
								<FormattedMessage id="login.code" values={{ msisdn: msisdnTruncated }}></FormattedMessage>
							</p>
							{renderFormik()}
							<p>
								<FormattedMessage id="login.nocode"></FormattedMessage>
							</p>
							<p>{renderReload()}</p>
							<p className="push-down">
								<HTMLMessage id="login.problems" />
							</p>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

Login.propTypes = {
	config: PropTypes.object,
	msisdnTruncated: PropTypes.string,
	onConfigUpdate: PropTypes.func,
	smsMock: PropTypes.bool,
};

export default Login;
