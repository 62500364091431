import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

const ErrorFocus = ({ formik }) => {
	useEffect(() => {
		const { isSubmitting, isValidating, errors } = formik;
		const keys = Object.keys(errors);
		if (keys.length > 0 && isSubmitting && !isValidating) {
			const element = document.querySelector(`.error input, .dropzone.error`);
			if (element) {
				const yOffset = -65;
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
				if (isSmoothScrollSupported) {
					setTimeout(() => {
						window.scrollTo({ top: y, behavior: 'smooth' });
					}, 10);
				} else {
					document.body.scrollTop = y;
					document.documentElement.scrollTop = y;
				}
				if (element.type !== 'file') {
					setTimeout(() => {
						element.focus();
					}, 500);
				}
			}
		}
	}, [formik]);
};

ErrorFocus.propTypes = {
	formik: PropTypes.object,
};

export default connect(ErrorFocus);
