import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import axios from 'axios';

const Language = (props) => {
	const intl = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const buttonClick = (event) => {
		if (event.target.getAttribute('data-lang')) {
			props.onConfigUpdate({ language: event.target.getAttribute('data-lang') });

			// save in session
			axios
				.post(`${process.env.APP_SERVER}`, { action: 'language', language: event.target.getAttribute('data-lang') })
				.then((result) => {
					if (process.env.DEV) console.log(result);
				})
				.catch((error) => {
					if (process.env.DEV) console.log(error);
				});
		}
		setIsOpen(!isOpen);
		event.preventDefault();
	};

	return (
		<OutsideClickHandler
			onOutsideClick={() => {
				if (!isOpen) return;
				setIsOpen(false);
			}}
		>
			<div className={isOpen ? 'language-selection open' : 'language-selection'}>
				<ul>
					<li className="label">
						<button type="button" data-lang="" onClick={buttonClick}>
							{intl.locale.toUpperCase()}
						</button>
					</li>
					<li className={intl.locale === 'de' ? 'active' : ''}>
						<button type="button" data-lang="de" onClick={buttonClick}>
							DE
						</button>
					</li>
					<li className={intl.locale === 'fr' ? 'active' : ''}>
						<button type="button" data-lang="fr" onClick={buttonClick}>
							FR
						</button>
					</li>
					<li className={intl.locale === 'it' ? 'active' : ''}>
						<button type="button" data-lang="it" onClick={buttonClick}>
							IT
						</button>
					</li>
					<li className={intl.locale === 'en' ? 'active' : ''}>
						<button type="button" data-lang="en" onClick={buttonClick}>
							EN
						</button>
					</li>
				</ul>
			</div>
		</OutsideClickHandler>
	);
};

Language.propTypes = {
	onConfigUpdate: PropTypes.func,
};

export default Language;
