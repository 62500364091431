import * as Yup from 'yup';
import { isString } from 'lodash';
import { isPast, isFuture, differenceInDays } from 'date-fns';
import { validateAHV, validateFile, validateIBAN, validateIBANnotQR } from './Utils';

const personal = 1;
const partner = 2;
const children = 3;
const financial = 4;
const uploads = 5;
const documents = 6;

class Schema {
	static load(section, values) {
		let hasPartner = false;
		let isSingle = false;
		let astag = null;
		let shape = {};
		let needsAdresse1 = false;

		switch (section) {
			case personal:
				astag = values.ASTAT;
				needsAdresse1 = values.INT_KORRESPOND_ABW === 1 || values.ZWOCH === 'X';

				return Yup.object().shape({
					ANRED: Yup.mixed()
						.default('')
						.test('mixed', 'forms.anrede_error', (value) => {
							if (isString(value) && value === '') return false;
							if (parseInt(value, 10) <= 0) return false;
							return true;
						}),

					VORNA: Yup.string().default('').required('forms.vorname_error').min(2, 'forms.vorname_error'),

					NACHN: Yup.string().default('').required('forms.nachname_error').min(2, 'forms.nachname_error'),

					NAME2: Yup.string()
						.default('')
						.when('INT_GEBNAME_ABW', {
							is: 1,
							then: Yup.string().required('forms.geburtsname_error').min(2, 'forms.geburtsname_error'),
						}),

					GBDAT: Yup.string()
						.default('0000-00-00')
						.required('forms.geburtsdatum_error')
						.test('date', 'forms.geburtsdatum_error', (value) => value !== '0000-00-00')
						.test('date_past', 'forms.geburtsdatum_past', (value) => isPast(new Date(value))),

					STRAS_2: Yup.string().required('forms.strasse_error').min(2, 'forms.strasse_error'),

					PSTLZ_2: Yup.string().required('forms.required_generic').min(2, 'forms.required_generic'),

					ORT01_2: Yup.string().required('forms.required_generic').min(2, 'forms.required_generic'),

					LAND1_2: Yup.string().required('forms.land_error'),

					STATE_2: Yup.string().when('LAND1_2', {
						is: 'CH',
						then: Yup.string().required('forms.kanton_error'),
					}),

					ZWOCH: Yup.string().default(''),

					STRAS_1: Yup.string().when('needsAdresse1', {
						is: () => needsAdresse1,
						then: Yup.string().required('forms.strasse_error').min(2, 'forms.strasse_error'),
					}),

					PSTLZ_1: Yup.string().when('needsAdresse1', {
						is: () => needsAdresse1,
						then: Yup.string().required('forms.required_generic').min(2, 'forms.required_generic'),
					}),

					ORT01_1: Yup.string().when('needsAdresse1', {
						is: () => needsAdresse1,
						then: Yup.string().required('forms.required_generic').min(2, 'forms.required_generic'),
					}),

					LAND1_1: Yup.string().when('needsAdresse1', {
						is: () => needsAdresse1,
						then: Yup.string().required('forms.land_error'),
					}),

					STATE_1: Yup.string().when('needsAdresse1', {
						is: () => needsAdresse1,
						then: Yup.string().when('LAND1_1', {
							is: 'CH',
							then: Yup.string().required('forms.kanton_error'),
						}),
					}),

					NATIO: Yup.string().default('').required('forms.staatsangeh_error').min(2, 'forms.staatsangeh_error'),

					ASTAT: Yup.string()
						.default('')
						.when('NATIO', {
							is: (NATIO) => NATIO !== 'CH',
							then: Yup.string().required('forms.aufenhaltsbew_error').min(1, 'forms.aufenhaltsbew_error'),
						}),

					ZRUEC: Yup.string()
						.default('')
						.when('ASTAT', {
							is: (ASTAT) => ASTAT === 'G' && values.NATIO !== 'CH',
							then: Yup.string().test(
								'number',
								'forms.required_generic',
								(value) => value !== '' && parseInt(value, 10) > 0,
							),
						}),

					BEWNR: Yup.string()
						.default('')
						.when('NATIO', {
							is: (NATIO) => NATIO !== 'CH' && astag !== '1',
							then: Yup.string().required('forms.zemis_error'),
						}),

					ABLAD: Yup.string()
						.default('0000-00-00')
						.when('NATIO', {
							is: (NATIO) => NATIO !== 'CH' && astag !== '1',
							then: Yup.string()
								.required('forms.zemis_gueltig_error')
								.test('date', 'forms.zemis_gueltig_error', (value) => value !== '0000-00-00')
								.test('date_future', 'forms.zemis_gueltig_future', (value) => isFuture(new Date(value))),
						}),

					KONFE: Yup.string()
						.default('')
						.when('NATIO', {
							is: (NATIO) => NATIO !== 'CH',
							then: Yup.string().required('forms.konfession_error'),
						}),

					NAME2_4: Yup.string().required('forms.required_generic').min(2, 'forms.required_generic').default(''),
					PHONE_4: Yup.string().required('forms.required_generic').min(9, 'forms.required_generic').default(''),
					ZZBEZIEHUNG_4: Yup.string().default(''),

					Z_SCI_ADPV: Yup.string().default(''),
				});

			case partner:
				isSingle = values.FAMST === '0';
				hasPartner = values.FAMST === '1' || values.FAMST === '4' || values.FAMST === '5';

				if (isSingle)
					return Yup.object().shape({
						FAMST: Yup.string().default('').required('forms.required_generic'),
					});

				if (!hasPartner)
					return Yup.object().shape({
						FAMST: Yup.string().default('').required('forms.required_generic'),

						FAMDT: Yup.string()
							.default('0000-00-00')
							.when('FAMST', {
								is: (FAMST) => FAMST !== '0',
								then: Yup.string()
									.required('forms.required_generic')
									.test('date', 'forms.required_generic', (value) => value !== '0000-00-00'),
							}),
					});

				return Yup.object().shape({
					FAMST: Yup.string().default('').required('forms.required_generic'),

					ZGEDT: Yup.string()
						.default('0000-00-00')
						.when('FAMST', {
							is: (FAMST) => FAMST === '4',
							then: Yup.string()
								.required('forms.required_generic')
								.test('date', 'forms.required_generic', (value) => value !== '0000-00-00'),
						}),

					FAMDT: Yup.string()
						.default('0000-00-00')
						.when('FAMST', {
							is: (FAMST) => FAMST === '1' || FAMST === '4' || FAMST === '5',
							then: Yup.string()
								.required('forms.required_generic')
								.test('date', 'forms.required_generic', (value) => value !== '0000-00-00'),
						}),

					ZPART_FASEX: Yup.mixed()
						.default('')
						.test('number', 'forms.anrede_p_error', (value) => value !== '' && parseInt(value, 10) > 0),

					ZPART_FAVOR: Yup.string().default('').required('forms.vorname_p_error').min(2, 'forms.vorname_p_error'),

					ZPART_FANAM: Yup.string().default('').required('forms.nachname_p_error').min(2, 'forms.nachname_p_error'),

					ZPART_FGBNA: Yup.string()
						.default('')
						.when('INT_PARTGEBNAME_ABW', {
							is: 1,
							then: Yup.string().required('forms.geburtsname_p_error').min(2, 'forms.geburtsname_p_error'),
						}),

					ZPART_FGBDT: Yup.string()
						.default('0000-00-00')
						.required('forms.geburtsdatum_p_error')
						.test('date', 'forms.geburtsdatum_p_error', (value) => value !== '0000-00-00')
						.test('date_past', 'forms.geburtsdatum_past', (value) => isPast(new Date(value))),

					STRAS_9: Yup.string().when('INT_PARTNRADR_ABW', {
						is: 1,
						then: Yup.string().required('forms.strasse_error').min(2, 'forms.strasse_error'),
					}),

					PSTLZ_9: Yup.string().when('INT_PARTNRADR_ABW', {
						is: 1,
						then: Yup.string().required('forms.required_generic').min(2, 'forms.required_generic'),
					}),

					ORT01_9: Yup.string().when('INT_PARTNRADR_ABW', {
						is: 1,
						then: Yup.string().required('forms.required_generic').min(2, 'forms.required_generic'),
					}),

					LAND1_9: Yup.string().when('INT_PARTNRADR_ABW', {
						is: 1,
						then: Yup.string().required('forms.land_error'),
					}),

					ZPART_FANAT: Yup.string()
						.default('')
						.required('forms.nationalitaet_p_error')
						.min(2, 'forms.nationalitaet_p_error'),

					ZPART_ZAUFB: Yup.string()
						.default('')
						.when('ZPART_FANAT', {
							is: (ZPART_FANAT) => ZPART_FANAT !== 'CH',
							then: Yup.string().required('forms.aufenhaltsbew_p_error').min(1, 'forms.aufenhaltsbew_p_error'),
						}),

					ZPART_ZQSTE: Yup.string()
						.default('')
						.when('ZPART_FANAT', {
							is: (ZPART_FANAT) => ZPART_FANAT !== 'CH',
							then: Yup.string().required('forms.required_generic'),
						}),

					ZPART_EMPYN: Yup.string().default('').required('forms.required_generic'),

					ZPART_ZARBG: Yup.string()
						.default('')
						.when('ZPART_EMPYN', {
							is: (ZPART_EMPYN) => ZPART_EMPYN === 'X',
							then: Yup.string().required('forms.arbeitgeber_error').min(2, 'forms.arbeitgeber_error'),
						}),

					ZPART_ZARBO: Yup.string()
						.default('')
						.when('ZPART_EMPYN', {
							is: (ZPART_EMPYN) => ZPART_EMPYN === 'X',
							then: Yup.string().required('forms.arbeitsort_error').min(2, 'forms.arbeitsort_error'),
						}),

					ZPART_ZEINA: Yup.string()
						.default('')
						.when('ZPART_EMPYN', {
							is: (ZPART_EMPYN) => ZPART_EMPYN === 'X',
							then: Yup.string().required('forms.erwerbsart_error'),
						}),

					ZPART_PAYBEGDA: Yup.string()
						.default('')
						.when('ZPART_EMPYN', {
							is: (ZPART_EMPYN) => ZPART_EMPYN === 'X',
							then: Yup.string().test('date', 'forms.required_generic', (value) => value !== '0000-00-00'),
						}),

					ZPART_PAYENDDA: Yup.string()
						.default('0000-00-00')
						.when('ZPART_EMPYN', {
							is: (ZPART_EMPYN) => ZPART_EMPYN === 'X',
							then: Yup.string(),
						}),

					ZPART_NAHVN: Yup.string()
						.default('')
						.when('ZPART_EMPYN', {
							is: (ZPART_EMPYN) => ZPART_EMPYN === 'X',
							then: Yup.string().test('ahv', 'forms.sozversnr_kind_error', (value) => validateAHV(value)),
						}),

					ZPART_ZERSA: Yup.string().default('').required('forms.required_generic'),

					ZPART_ZERSD: Yup.string()
						.default('0000-00-00')
						.when('ZPART_ZERSA', {
							is: (ZPART_ZERSA) => ZPART_ZERSA === 'X',
							then: Yup.string().test(
								'date',
								'forms.required_generic',
								(value) => value !== '0000-00-00' && value !== '',
							),
						}),
				});

			case children:
				return Yup.object().shape({
					ZKIND: Yup.array().of(
						Yup.object().shape({
							FAVOR: Yup.string().default('').required('forms.vorname_p_error').min(2, 'forms.vorname_p_error'),

							FANAM: Yup.string().default('').required('forms.nachname_p_error').min(2, 'forms.nachname_p_error'),

							FASEX: Yup.string().default('').required('forms.required_generic').min(1, 'forms.required_generic'),

							FGBDT: Yup.string()
								.default('0000-00-00')
								.required('forms.geburtsdatum_p_error')
								.test('date', 'forms.geburtsdatum_p_error', (value) => value !== '0000-00-00')
								.test('date_past', 'forms.geburtsdatum_past', (value) => isPast(new Date(value))),

							ZINCH: Yup.string().default('').required('forms.required_generic').min(1, 'forms.required_generic'),
							NAHVN: Yup.string()
								.default('')
								.when('ZINCH', {
									is: 'X',
									then: Yup.string()
										.required('forms.sozversnr_kind_error')
										.test('ahv', 'forms.sozversnr_kind_error', (value) => validateAHV(value)),
								}),

							KDUTB: Yup.string().default('').required('forms.required_generic'),

							KDSVH: Yup.string().default('').required('forms.required_generic'),
						}),
					),
					INT_KINDER: Yup.number().default(0),
				});

			case financial:
				return Yup.object().shape({
					NAHVN: Yup.string().when('PERSK', {
						is: () => values.NATIO === 'CH',
						then: Yup.string()
							.default('')
							.required('forms.sozversnummer_error')
							.test('ahv', 'forms.sozversnummer_error', (value) => validateAHV(value)),
						otherwise: Yup.string()
							.default('')
							.test('ahv', 'forms.sozversnummer_error', (value) => validateAHV(value)),
					}),

					ZANZL: Yup.string()
						.default('')
						.when('PERSK', {
							is: (PERSK) => PERSK === '20' || PERSK === '35',
							then: Yup.string().test(
								'number',
								'forms.required_generic',
								(value) => value !== '' && parseInt(value, 10) > 0,
							),
						}),

					ZBANK: Yup.string().default('').required('forms.required_generic'),

					IBAN: Yup.string()
						.default('')
						.when('ZBANK', {
							is: 'X',
							then: Yup.string()
								.required('forms.iban_error')
								.test('iban', 'forms.iban_error', (value) => validateIBAN(value))
								.test('iban', 'forms.iban_qr_error', (value) => validateIBANnotQR(value)),
						}),

					LOBNR: Yup.string()
						.default('')
						.when('ZGSBB', {
							is: (ZGSBB) => ZGSBB === 'X',
							then: Yup.string().test(
								'number',
								'forms.required_generic',
								(value) => value === '' || value.length === 10,
							),
						}),

					ZFSBB: Yup.string()
						.default('')
						.when('ZGSBB', {
							is: () => values.ZGSBB === '' && (values.PERSK === '20' || values.PERSK === '35'),
							then: Yup.string().test(
								'number',
								'forms.required_generic',
								(value) => value !== '' && parseInt(value, 10) > 0,
							),
						}),

					// gültig ab, 06.11.23
					ZFSDT: Yup.string()
						.default('0000-00-00')
						.when('ZFSBB', {
							is: (ZFSBB) => ZFSBB === '3' || ZFSBB === '4',
							then: Yup.string()
								.required('forms.required_generic')
								.test('date', 'forms.required_generic', (value) => value !== '0000-00-00')
								.test(
									'date_future',
									'forms.railcheck_future',
									(value) => Math.floor(differenceInDays(new Date(value), new Date(values.ZEINTR))) >= 0,
								),
						}),

					NBESC: Yup.string()
						.default('')
						.test('number', 'forms.required_generic', (value) => value !== '' && parseInt(value, 10) > 0),

					TAETE: Yup.string()
						.default('')
						.when('NBESC', {
							is: (NBESC) => NBESC === '1',
							then: Yup.string().required('forms.nebenerwerb_art_error').min(2, 'forms.nebenerwerb_art_error'),
						}),

					WOSTD: Yup.string()
						.default('')
						.when('NBESC', {
							is: (NBESC) => NBESC === '1',
							then: Yup.string().test(
								'number',
								'forms.nebenerwerb_umfang_error',
								(value) => value !== '' && parseInt(value, 10) > 0,
							),
						}),
				});

			case uploads:
				shape = {
					ZVERP: Yup.string().default('').required('forms.required_generic'),

					ZVERD: Yup.string().when('ZVERP', {
						is: (ZVERP) => ZVERP === 'X',
						then: Yup.string()
							.default('0000-00-00')
							.required('forms.vertrag_sendedatum_error')
							.test('date', 'forms.vertrag_sendedatum_error', (value) => value !== '0000-00-00'),
					}),

					// Vertrag
					ZDK01: Yup.mixed().when('ZVERP', {
						is: (ZVERP) => ZVERP !== 'X',
						then: Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file)),
					}),

					// Foto
					ZDK02: Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file)),

					// ID/Pass
					ZDK11: Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file)),
				};

				// Vereinbarung Privatfahrzeug
				// values.ZAUTO = '1200.00';
				if (values.ZAUTO !== '')
					shape.ZDK03 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Strafregisterauszug
				// values.ZSLVL = '1';
				if (values.ZSLVL && (values.ZSLVL === '1' || values.ZSLVL === '2' || values.ZSLVL === '3'))
					shape.ZDK04 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Betreibungsregisterauszug
				// values.ZSLVL = '2';
				if (values.ZSLVL && values.ZSLVL === '2')
					shape.ZDK05 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Vertraulichkeitserklärung
				// values.ZSLVL = '1';
				if (values.ZSLVL && (values.ZSLVL === '1' || values.ZSLVL === '2'))
					shape.ZDK06 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Zeitautonomie
				// values.ZZAUT = 'X';
				if (values.ZZAUT === 'X')
					shape.ZDK07 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Ansässigkeitsbescheinigung
				// values.LAND1_2 = 'DE';
				// values.ASTAT = 'G';
				// values.ZRUEC = '1';
				if (values.LAND1_2 === 'DE' && values.ASTAT === 'G' && values.ZRUEC === '1')
					shape.ZDK08 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Bescheinigung über den französischen Steuerwohnsitz
				// values.LAND1_2 = 'FR';
				// values.ASTAT = 'G';
				// values.ZRUEC = '1';
				// values.KANTON = 'GE';
				if (
					values.LAND1_2 === 'FR' &&
					values.ASTAT === 'G' &&
					values.ZRUEC === '1' &&
					['VD', 'NE', 'SO', 'BL', 'BS', 'BE', 'VS', 'JU'].indexOf(values.KANTON) > -1
				)
					shape.ZDK09 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Lastschriftanmeldung
				// values.LAND1_2 = 'FR';
				// values.ASTAT = 'G';
				// values.KANTON = 'GE';
				// values.ZPART_ZAUFB = 'A';
				// values.ZPART_FANAT = 'GR';
				hasPartner = values.ZPART_FASEX !== '' && values.ZPART_ZAUFB !== 'C' && values.ZPART_FANAT !== 'CH';
				if (
					(values.LAND1_2 === 'FR' && values.ASTAT === 'G' && values.KANTON === 'GE') ||
					(hasPartner && values.STATE_2 === 'GE' && ['B', 'L', 'G'].indexOf(values.ASTAT) > -1)
				)
					shape.ZDK10 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Ausländerausweis
				// values.NATIO = 'BD';
				// values.ASTAT = '1';
				// Nur anzeigen: NATIO = "nicht Schweiz“
				// Nicht anzeigen, wenn "ASTAT" = "keine" aus ValueHelps
				if (values.NATIO !== 'CH' && values.ASTAT !== '1')
					shape.ZDK12 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Partner ID et.
				// values.NATIO = 'US';
				// values.ZPART_FANAT = 'CH';
				// values.ZPART_ZAUFB = 'C';
				if ((values.NATIO !== 'CH' && values.ZPART_FANAT === 'CH') || values.ZPART_ZAUFB === 'C')
					shape.ZDK13 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Eheurkunde
				// values.FAMST = '1';
				if (values.FAMST === '1' || values.FAMST === '4' || values.FAMST === '5')
					shape.ZDK14 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Familienbüchlein
				// was: values.FAMST === '1' || values.FAMST === '4' || values.FAMST === '5'
				if (values.ZKIND.length > 0)
					shape.ZDK15 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Scheidungsurkunde
				if (values.FAMST === '3')
					shape.ZDK18 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Grenzgänger Fragebogen
				// values.ASTAT = 'G';
				if (values.ASTAT === 'G')
					shape.ZDK16 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// console.log(shape);
				// console.log(values);

				return Yup.object().shape(shape);

			case documents:
				return Yup.object().shape({
					ZCONF: Yup.string().default('').required('forms.beilagen_error').min(1, 'forms.beilagen_error'),
				});

			default:
				console.log('Schema load: unknown section', section);
				return Yup.object();
		}
	}

	static get personal() {
		return personal;
	}

	static get partner() {
		return partner;
	}

	static get children() {
		return children;
	}

	static get financial() {
		return financial;
	}

	static get uploads() {
		return uploads;
	}

	static get documents() {
		return documents;
	}
}

export default Schema;
