import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { format } from 'date-fns';
import Layout from './Layout';
import Input from '../components/Input';
import InputRadio from '../components/InputRadio';
import InputRadioGroup from '../components/InputRadioGroup';
import ErrorFocus from '../components/ErrorFocus';
import HTMLMessage from '../components/HTMLMessage';
import Data from '../modules/Data';
import Schema from '../modules/Schema';

const Financial = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const formikFormRef = useRef();
	const schema = Schema.load(Schema.financial, props.config.data);
	const initialValues = schema.cast(Data.dataToValues(Data.financial, props.config.data));

	const saveForm = (values) => {
		const { data, home } = props.config;
		Data.saveSection(Data.financial, values, data, navigate, home);
	};

	const renderFormik = () => {
		const { data } = props.config;

		return (
			<Formik
				isInitialValid
				initialValues={initialValues}
				validationSchema={schema}
				innerRef={formikFormRef}
				onSubmit={saveForm}
			>
				{({ values, errors, touched, setFieldValue }) => (
					<Form>
						<div className="form-header">
							<h1 className="smaller">
								<FormattedMessage id="home.financial"></FormattedMessage>
							</h1>
							<button onClick={() => navigate(-1)} type="button" className="close-button">
								&nbsp;
							</button>
						</div>
						<h3>
							<FormattedMessage id="section.sozversnummer"></FormattedMessage>
						</h3>

						<Field
							component={Input}
							type="text"
							name="NAHVN"
							label={data.NATIO !== 'CH' ? 'forms.sozversnummer' : 'forms.sozversnr_kind'}
							placeholder="forms.sozversnummer_placeholder"
							maxLength="16"
							mask={{ numericOnly: true, blocks: [3, 4, 4, 2], delimiter: '.' }}
						/>
						<h3>
							<FormattedMessage id="section.lohnzahlung"></FormattedMessage>
						</h3>
						{(data.PERSK === '20' || data.PERSK === '35') && (
							<InputRadioGroup label="forms.basislohn" name="ZANZL" values={values} errors={errors} touched={touched}>
								<Field component={InputRadio} name="ZANZL" label="forms.basislohn_01" value="01" valueFormat="string" />
								<Field component={InputRadio} name="ZANZL" label="forms.basislohn_02" value="02" valueFormat="string" />
							</InputRadioGroup>
						)}

						<InputRadioGroup label="forms.chkonto" name="ZBANK" values={values} errors={errors} touched={touched}>
							<Field component={InputRadio} name="ZBANK" label="forms.label.yes" value="X" valueFormat="string" />
							<Field component={InputRadio} name="ZBANK" label="forms.label.no" value="O" valueFormat="string" />
						</InputRadioGroup>
						{values.ZBANK === 'X' && (
							<Field
								component={Input}
								type="text"
								name="IBAN"
								label="forms.iban"
								placeholder="forms.iban_placeholder"
								maxLength="26"
								mask={{ blocks: [4, 4, 4, 4, 4, 1], delimiter: ' ', uppercase: true }}
							/>
						)}
						{((data.ZGSBB === '' && (data.PERSK === '20' || data.PERSK === '35')) || data.ZGSBB === 'X') && (
							<h3>
								<FormattedMessage id="section.sbb"></FormattedMessage>
							</h3>
						)}
						{data.ZGSBB === 'X' && (
							<>
								<HTMLMessage id="forms.sbb_kdnr_xplain"></HTMLMessage>
								<Field
									component={Input}
									type="text"
									name="LOBNR"
									label="forms.sbb_kdnr"
									placeholder="forms.sbb_kdnr_placeholder"
									maxLength="13"
									mask={{ numericOnly: true, blocks: [3, 3, 3, 1], delimiter: '-' }}
								/>
							</>
						)}
						{data.ZGSBB === '' && (data.PERSK === '20' || data.PERSK === '35') && (
							<InputRadioGroup
								label="forms.sbb_verguetung"
								name="ZFSBB"
								values={values}
								errors={errors}
								touched={touched}
							>
								{intl.messages.ZFSBB.map((object) => {
									if (data.AGE > 25 || (object.key !== '3' && object.key !== '4')) {
										return (
											<Field
												component={InputRadio}
												name="ZFSBB"
												label="forms.missing"
												labelI18n={object.value}
												value={object.key}
												valueFormat="string"
												key={object.key}
											/>
										);
									}
									return null;
								})}
							</InputRadioGroup>
						)}
						{values.ZFSBB === '2' && (
							<p>
								<HTMLMessage id="section.sbb_halbtax_explain"></HTMLMessage>
							</p>
						)}
						{(values.ZFSBB === '3' || values.ZFSBB === '4') && (
							<Field
								component={Input}
								type="date"
								name="ZFSDT"
								label="forms.railcheck_gueltig"
								placeholder="forms.datum_placeholder"
								validationData={{ datum: format(new Date(values.ZEINTR || null), 'dd.MM.yyyy') }}
							/>
						)}
						<h3>
							<FormattedMessage id="section.nebenerwerb"></FormattedMessage>
						</h3>
						<InputRadioGroup label="forms.nebenerwerb" name="NBESC" values={values} errors={errors} touched={touched}>
							<Field component={InputRadio} name="NBESC" label="forms.label.yes" value="1" valueFormat="string" />
							<Field component={InputRadio} name="NBESC" label="forms.label.no" value="2" valueFormat="string" />
						</InputRadioGroup>
						{values.NBESC === '1' && (
							<div className="input-field">
								<Field
									component={Input}
									type="text"
									name="TAETE"
									label="forms.nebenerwerb_art"
									placeholder="forms.nebenerwerb_placeholder"
									maxLength="30"
								/>
								<Field
									component={Input}
									type="text"
									name="WOSTD"
									label="forms.nebenerwerb_umfang"
									placeholder="forms.nebenerwerb_umfang_placeholder"
									maxLength="5"
									onChange={(e) => {
										const { value } = e.target;
										const regex = /^[0-9]{0,5}$/;
										if (value === '') setFieldValue('WOSTD', '');
										else if (value === '' || (value.length <= 5 && regex.test(value))) {
											setFieldValue('WOSTD', String(parseInt(value, 10)));
										}
									}}
								/>
							</div>
						)}
						<ErrorFocus />
						<div className="button-group move-down">
							<button type="button" className="button secondary" onClick={() => navigate(-1)}>
								<FormattedMessage id="button.cancel"></FormattedMessage>
							</button>
							<button type="submit" className="button">
								<FormattedMessage id="button.save"></FormattedMessage>
							</button>
						</div>
						<div className="required">
							<FormattedMessage id="forms.required_fields"></FormattedMessage>
						</div>
						{true && process.env.DEV && (
							<div>
								<hr />
								<pre>{JSON.stringify(values, 0, 2)}</pre>
								<pre>{JSON.stringify(errors, 0, 2)}</pre>
							</div>
						)}
					</Form>
				)}
			</Formik>
		);
	};

	return (
		<Layout title="app.title" {...props}>
			<div className="content form">{renderFormik()}</div>
		</Layout>
	);
};

Financial.propTypes = {
	config: PropTypes.object,
};

export default Financial;
