import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Layout from './Layout';
import InputRadio from '../components/InputRadio';
import InputRadioGroup from '../components/InputRadioGroup';
import InputCheckbox from '../components/InputCheckbox';
import ErrorFocus from '../components/ErrorFocus';

const Offer = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const formikFormRef = useRef();

	const formSchema = Yup.object().shape({
		decision: Yup.number().min(1, 'forms.required_generic'),
		reason: Yup.string().when('decision', {
			is: 2,
			then: Yup.string().required('forms.required_generic').min(3, 'forms.required_generic'),
		}),
	});

	const checkRapsheet = parseInt(props.config.data.ZSLVL, 10) > 0;
	const initialValues = {
		decision: parseInt(props.config.data.ZOFFA, 10),
		reason: props.config.data.ZOFFG,
		rapsheet: checkRapsheet ? '' : 'X',
	};

	const anrede =
		parseInt(props.config.data.ANRED, 10) === 1
			? intl.formatMessage({ id: 'title.male' })
			: intl.formatMessage({ id: 'title.female' });
	const anredeFull = `${anrede} ${props.config.data.VORNA}`;
	const pdfFilename = `${process.env.APP_SERVER}/download/${intl.formatMessage({ id: 'offer.offer' })}.pdf`;

	const { config, onConfigUpdate } = props;

	const offerReply = (values) => {
		if (values.decision === 1) {
			values.reason = '0';
		}
		values = Object.assign(values, { action: 'offer-decision' });
		setIsLoading(true);
		axios.post(`${process.env.APP_SERVER}`, values).then((result) => {
			if (process.env.DEV) console.log(result.data);
			if (result.data.result && result.data.page) {
				navigate(`/${result.data.page}`, { replace: true });
			}
		});
	};

	const renderPdfs = () => {
		const pdfs = [];
		props.config.data.OFFLI.forEach((element, index) => {
			pdfs.push(
				<li key={`pdf-${index}`}>
					<a className="file pdf" target="_blank" href={element.LINK[props.config.lang]}>
						{element.TEXT[props.config.lang]}
					</a>
				</li>,
			);
		});
		return pdfs;
	};

	const renderFormik = () => (
		<Formik
			isInitialValid
			initialValues={initialValues}
			validationSchema={formSchema}
			innerRef={formikFormRef}
			onSubmit={offerReply}
		>
			{({ values, setFieldTouched, errors, touched }) => (
				<div className="align-left">
					<Form>
						<h3>
							<FormattedMessage id="offer.subtitle"></FormattedMessage>
						</h3>
						<ul className="pdf-list">
							<li>
								<a target="_blank" href={pdfFilename} className="file pdf">
									<FormattedMessage id="offer.offer"></FormattedMessage>
								</a>
							</li>
							{renderPdfs()}
						</ul>
						<h3>
							<FormattedMessage id="offer.acceptance"></FormattedMessage>
						</h3>
						<InputRadioGroup name="decision" values={values} errors={errors} touched={touched}>
							<Field component={InputRadio} name="decision" label="forms.label.yes" value={1} valueFormat="string" />
							<Field
								component={InputRadio}
								onChange={() => {
									setFieldTouched('decision', false);
									setFieldTouched('reason', false);
								}}
								name="decision"
								label="forms.label.no"
								value={2}
								valueFormat="string"
							/>
						</InputRadioGroup>
						{getIn(values, 'decision') === 2 && (
							<div>
								<h3>
									<FormattedMessage id="offer.reason"></FormattedMessage>
								</h3>
								<InputRadioGroup name="reason" values={values} errors={errors} touched={touched} vertical>
									{intl.messages.ZOFFG.map((object) => (
										<Field
											component={InputRadio}
											name="reason"
											label="offer.label.alternate"
											labelI18n={object.value}
											value={object.key}
											valueFormat="string"
											key={object.key}
										/>
									))}
								</InputRadioGroup>
							</div>
						)}

						{values.decision === 1 && checkRapsheet && (
							<div className="checkbox-group" style={{ marginTop: '1.5rem' }}>
								<Field
									component={InputCheckbox}
									name="rapsheet"
									label={props.config.data.ZSLVL === '2' ? 'forms.rapsheet2' : 'forms.rapsheet1and3'}
									checkedValue="X"
									uncheckedValue=""
								/>
							</div>
						)}

						<ErrorFocus />
						<div className="button-group single move-down">
							<button
								type="submit"
								disabled={values.decision === 1 && values.rapsheet !== 'X'}
								className={isLoading ? 'button spinner' : 'button'}
							>
								<FormattedMessage id="button.save"></FormattedMessage>
								<div className="loader-spinner--small"></div>
							</button>
						</div>
						<div className="required">
							<FormattedMessage id="forms.required_fields"></FormattedMessage>
						</div>
						{true && process.env.DEV && (
							<div>
								<hr />
								<pre>{JSON.stringify(values, 0, 2)}</pre>
								<pre>{JSON.stringify(errors, 0, 2)}</pre>
							</div>
						)}
					</Form>
				</div>
			)}
		</Formik>
	);

	return (
		<Layout title="app.title" config={config} onConfigUpdate={onConfigUpdate}>
			<div className="content form-offer">
				<div className="content-inner">
					<h1 className="smaller">{anredeFull}</h1>
					<p>
						<FormattedMessage id="offer.text"></FormattedMessage>
					</p>
				</div>
				{renderFormik()}
			</div>
		</Layout>
	);
};

Offer.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Offer;
