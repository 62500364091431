import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const Video = ({ src, image, textTitle, textPlay }) => {
	const intl = useIntl();
	const videoRef = useRef();
	const videoWrapRef = useRef();

	const [isVideoActive, setIsVideoActive] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const [isMuted, setIsMuted] = useState(false);
	const [progress, setProgress] = useState(0);

	const onPlay = (event) => {
		setIsVideoActive(true);
		event.preventDefault();
	};

	const onPlayPause = (event) => {
		try {
			if (isPaused) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
			}
		} catch (error) {
			if (process.env.DEV) console.log(error);
		}

		setIsPaused(!isPaused);
		event.preventDefault();
	};

	const onPlayEnd = (event) => {
		setTimeout(() => setIsVideoActive(false), 5000);
		event.preventDefault();
	};

	const onLoadedMetadata = (event) => {
		if (process.env.DEV) console.log('onLoadedMetadata', event.currentTarget.duration);
	};

	const onTimeUpdate = (event) => {
		setProgress((event.currentTarget.currentTime / event.currentTarget.duration) * 100);
	};

	const onToggleMute = (event) => {
		if (!isMuted) {
			videoRef.current.muted = true;
			videoWrapRef.current.classList.add('is-muted');
		} else {
			videoRef.current.muted = false;
			videoWrapRef.current.classList.remove('is-muted');
		}
		setIsMuted(!isMuted);
		event.preventDefault();
	};

	if (isVideoActive) {
		return (
			<div className="video-wrap">
				<div ref={videoWrapRef} className="video">
					<video
						autoPlay
						muted={isMuted}
						playsInline
						poster={intl.formatMessage({ id: image })}
						src={intl.formatMessage({ id: src })}
						ref={videoRef}
						onEnded={onPlayEnd}
						onTimeUpdate={onTimeUpdate}
						onClick={onPlayPause}
						onLoadedMetadata={onLoadedMetadata}
					></video>
					<div className="progress" style={{ width: `${progress}%` }}></div>
					<button className="mute" type="button" onClick={onToggleMute}>
						Audio
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="video-wrap">
			<img alt="" src={intl.formatMessage({ id: image })} />
			<div className="overlay">
				<h1 className="smaller">
					<FormattedMessage id={textTitle}></FormattedMessage>
				</h1>
				<p>
					<button type="button" onClick={onPlay}>
						<FormattedMessage id={textPlay}></FormattedMessage>
					</button>
				</p>
			</div>
		</div>
	);
};

Video.propTypes = {
	image: PropTypes.string,
	src: PropTypes.string,
	textPlay: PropTypes.string,
	textTitle: PropTypes.string,
};

export default Video;
