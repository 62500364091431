import React from 'react';
import { useIntl } from 'react-intl';
import Layout from './Layout';

const BadRequest = (props) => {
	const intl = useIntl();

	const createMarkup = () => ({
		__html: intl.formatMessage({
			id: 'app.badrequest.text',
		}),
	});

	return (
		<Layout title="app.title" {...props}>
			<div className="content">
				<div className="content-inner">
					<p dangerouslySetInnerHTML={createMarkup()}></p>
				</div>
			</div>
		</Layout>
	);
};

export default BadRequest;
