import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './views/App';

if (!process.env.DEV) {
	Sentry.init({
		dsn: 'https://c4c0c296f2dc47f59b4c0b4900cf8acb@o447098.ingest.sentry.io/5426685',
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App key="app" />);
