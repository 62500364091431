import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Ping from '../components/Ping';
import ScrollToTop from '../components/ScrollToTop';
import Language from '../components/Language';

const Layout = ({ title, children, config, onConfigUpdate }) => {
	const intl = useIntl();
	const navigate = useNavigate();

	useEffect(() => {
		function handleKey(e) {
			if (e.keyCode === 27) {
				const paths = ['/personal-details', '/partner', '/children', '/financial', '/uploads', '/documents'];
				if (paths.indexOf(document.location.pathname) > -1) {
					navigate(-1);
				}
			}
		}
		window.addEventListener('keydown', handleKey);

		return () => {
			window.removeEventListener('keydown', handleKey);
		};
	}, []);

	const logout = () => {
		const newConfig = config;
		newConfig.isAuthenticated = false;
		onConfigUpdate(newConfig);
		axios
			.post(`${process.env.APP_SERVER}`, { action: 'logout' })
			.then((result) => {
				if (process.env.DEV) console.log(result);
				navigate('/logout', { replace: true });
				window.history.replaceState({}, null, '/logout');
			})
			.catch((error) => {
				if (process.env.DEV) console.log(error);
				navigate('/logout', { replace: true });
				window.history.replaceState({}, null, '/logout');
			});
	};

	return (
		<div className="main" tabIndex="-1">
			<FormattedMessage id={title}>
				{(localizedTitle) => (
					<Helmet>
						<title>{`${localizedTitle}`}</title>
					</Helmet>
				)}
			</FormattedMessage>
			<div key="header" className="header">
				<div className="wrap logo">
					<span key="logo-lifeform" className="logo-lifeform" aria-label="Swisscom Logo"></span>
					<span className="logo-type">
						<img alt="Swisscom" src="/images/swisscom-logo-type.svg" />
					</span>
				</div>
				{config && config.isAuthenticated && (
					<button className="button transparent logout" type="button" onClick={logout}>
						<FormattedMessage id="button.logout"></FormattedMessage>
					</button>
				)}
				<Language config={config} onConfigUpdate={onConfigUpdate} />
			</div>

			<div className="wrap content-wrap">
				<div id="content" className="main">
					{children}
					<div className="bg"></div>
				</div>
			</div>

			<div className="footer">
				<div className="wrap">
					<p>
						<a href={intl.formatMessage({ id: 'app.impressum.link' })} tabIndex="-1">
							<FormattedMessage id="app.impressum"></FormattedMessage>
						</a>
						<span> | </span>
						<a href={intl.formatMessage({ id: 'app.datenschutz.link' })} tabIndex="-1">
							<FormattedMessage id="app.datenschutz"></FormattedMessage>
						</a>
					</p>
					<p>&copy; Swisscom {new Date().getFullYear()}</p>
				</div>
			</div>
			<Ping config={config} onConfigUpdate={onConfigUpdate} navigate={navigate} />
			<ScrollToTop />
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
	title: PropTypes.string,
};

export default Layout;
