import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import Layout from './Layout';
import InputCheckbox from '../components/InputCheckbox';
import ErrorFocus from '../components/ErrorFocus';
import Data from '../modules/Data';
import Schema from '../modules/Schema';

const Documents = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const formikFormRef = useRef();
	const schema = Schema.load(Schema.documents, props.config.data);
	const initialValues = schema.cast(Data.dataToValues(Data.documents, props.config.data));
	const pdfFilename = `${process.env.APP_SERVER}/download/${intl.formatMessage({ id: 'forms.documents.offer' })}.pdf`;

	const saveForm = (values) => {
		const { data, home } = props.config;
		Data.saveSection(Data.documents, values, data, navigate, home);
	};

	const renderPdfs = () => {
		const pdfs = [];
		props.config.data.VERLI.forEach((element, index) => {
			pdfs.push(
				<li key={`pdf-${index}`}>
					<a className="file pdf" target="_blank" href={element.LINK[props.config.lang]}>
						{element.TEXT[props.config.lang]}
					</a>
				</li>,
			);
		});
		return pdfs;
	};

	const renderFormik = () => {
		let title = intl.formatMessage({ id: 'home.documents' });
		if (props.config.data.ZOFFE === '-1') title = `${title}*`;

		return (
			<Formik
				isInitialValid
				initialValues={initialValues}
				validationSchema={schema}
				innerRef={formikFormRef}
				onSubmit={saveForm}
			>
				{({ values, errors, touched }) => (
					<Form>
						<div className="form-header">
							<h1 className="smaller">{title}</h1>
							<button onClick={() => navigate(-1)} type="button" className="close-button">
								&nbsp;
							</button>
						</div>
						{props.config.data.ZOFFE !== '-1' && (
							<>
								<h3>
									<FormattedMessage id="section.offerte"></FormattedMessage>
								</h3>
								<ul className="pdf-list">
									<li>
										<a target="_blank" href={pdfFilename} className="file pdf">
											<FormattedMessage id="forms.documents.offer"></FormattedMessage>
										</a>
									</li>
								</ul>
								<h3>
									<FormattedMessage id="section.beilagen"></FormattedMessage>
								</h3>
							</>
						)}
						{props.config.data.ZOFFE === '-1' && <br />}
						<ul className="pdf-list">{renderPdfs()}</ul>
						<div className="checkbox-group">
							<Field
								component={InputCheckbox}
								name="ZCONF"
								label="forms.beilagen"
								checkedValue="X"
								uncheckedValue=""
								key="beilagen"
							/>
							{touched.ZCONF && errors.ZCONF && (
								<div className="message">
									<FormattedMessage id="forms.beilagen_error"></FormattedMessage>
								</div>
							)}
						</div>
						<ErrorFocus />
						<div className="button-group move-down">
							<button type="button" className="button secondary" onClick={() => navigate(-1)}>
								<FormattedMessage id="button.cancel"></FormattedMessage>
							</button>
							<button type="submit" className="button">
								<FormattedMessage id="button.save"></FormattedMessage>
							</button>
						</div>
						<div className="required">
							<FormattedMessage id="forms.required_fields"></FormattedMessage>
						</div>
						{false && process.env.DEV && (
							<div>
								<hr />
								<pre>{JSON.stringify(values, 0, 2)}</pre>
								<pre>{JSON.stringify(errors, 0, 2)}</pre>
							</div>
						)}
					</Form>
				)}
			</Formik>
		);
	};

	return (
		<Layout title="app.title" {...props}>
			<div className="content form">{renderFormik()}</div>
		</Layout>
	);
};

Documents.propTypes = {
	config: PropTypes.object,
	history: PropTypes.object,
};

export default Documents;
