import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Layout from './Layout';
import Input from '../components/Input';
import InputRadio from '../components/InputRadio';
import InputRadioGroup from '../components/InputRadioGroup';
import InputCheckbox from '../components/InputCheckbox';
import InputSelect from '../components/InputSelect';
import ErrorFocus from '../components/ErrorFocus';
import Data from '../modules/Data';
import Schema from '../modules/Schema';

const PersonalDetails = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const formikFormRef = useRef();
	const schema = Schema.load(Schema.personal, props.config.data);
	const initialValues = schema.cast(Data.dataToValues(Data.personal, props.config.data));

	const saveForm = (values) => {
		const { data, home } = props.config;
		Data.saveSection(Data.personal, values, data, navigate, home);
	};

	const wochenAufenthaltChanged = (values, setFieldValue) => {
		if (values.ZWOCH === '') {
			if (values.LAND1_2 === 'CH') setFieldValue('LAND1_2', '');
			setFieldValue('LAND1_1', 'CH');
		} else if (values.LAND1_2 === '') {
			setFieldValue('LAND1_2', props.config.data.LAND1_2);
		}
	};

	const renderFormik = () => (
		<Formik
			isInitialValid
			initialValues={initialValues}
			validationSchema={() => Yup.lazy((values) => Schema.load(Schema.personal, values))}
			innerRef={formikFormRef}
			onSubmit={saveForm}
		>
			{({ values, errors, touched, setFieldValue }) => (
				<Form>
					<div className="form-header">
						<h1 className="smaller">
							<FormattedMessage id="home.personal"></FormattedMessage>
						</h1>
						<button onClick={() => navigate(-1)} type="button" className="close-button">
							&nbsp;
						</button>
					</div>
					<h3>
						<FormattedMessage id="section.personal"></FormattedMessage>
					</h3>
					<InputRadioGroup label="forms.anrede" name="ANRED" values={values} errors={errors} touched={touched}>
						{intl.messages.ANRED.map((object) => (
							<Field
								component={InputRadio}
								name="ANRED"
								label="forms.missing"
								labelI18n={object.value}
								value={object.key}
								valueFormat="string"
								key={object.key}
							/>
						))}
					</InputRadioGroup>
					<Field
						component={Input}
						type="text"
						name="VORNA"
						label="forms.vorname"
						caption="forms.legalname"
						placeholder="forms.vorname_placeholder"
					/>
					<Field
						component={Input}
						type="text"
						name="NACHN"
						label="forms.nachname"
						caption="forms.legalname"
						placeholder="forms.nachname_placeholder"
					/>
					<div className="checkbox-group">
						<Field
							component={InputCheckbox}
							name="INT_GEBNAME_ABW"
							label="forms.geburtsname_abw"
							checkedValue={1}
							uncheckedValue={0}
							onChange={() => {
								if (values.INT_GEBNAME_ABW === 0)
									setTimeout(() => document.querySelector(`input[name=NAME2]`).focus(), 5);
							}}
						/>
					</div>
					{values.INT_GEBNAME_ABW === 1 && (
						<Field
							component={Input}
							type="text"
							name="NAME2"
							label="forms.geburtsname"
							placeholder="forms.geburtsname_placeholder"
						/>
					)}
					<Field
						component={Input}
						type="date"
						name="GBDAT"
						label="forms.geburtsdatum"
						placeholder="forms.datum_placeholder"
					/>
					<h3>
						<FormattedMessage id={values.ZWOCH === '' ? 'section.wohnadr' : 'section.wohn_ausland'}></FormattedMessage>
					</h3>
					<div className="checkbox-group checkbox-label-wide">
						<Field
							component={InputCheckbox}
							name="ZWOCH"
							label="forms.wochenaufenthalter"
							checkedValue="X"
							uncheckedValue=""
							onChange={() => wochenAufenthaltChanged(values, setFieldValue)}
						/>
					</div>
					<Field
						component={Input}
						type="text"
						name="STRAS_2"
						label="forms.strasse"
						placeholder="forms.strasse_placeholder"
					/>
					<div className="input-group">
						<label htmlFor="plzort">
							<FormattedMessage id="forms.plzort"></FormattedMessage>
						</label>
						<div className="input-group-plzort">
							<Field
								component={Input}
								type="text"
								name="PSTLZ_2"
								placeholder="forms.plz_placeholder"
								maxLength={10}
								hideErrors
								onChange={(e) => {
									let { value } = e.target;
									value = value.toUpperCase();
									const regex = /^[A-Z0-9\-\s]{0,10}$/;
									if (value === '' || (value.length <= 10 && regex.test(value))) {
										setFieldValue('PSTLZ_2', value);
									}
								}}
							/>
							<Field component={Input} type="text" name="ORT01_2" placeholder="forms.ort_placeholder" hideErrors />
						</div>
						{(touched.PSTLZ_2 || touched.ORT01_2) && (errors.PSTLZ_2 || errors.ORT01_2) && (
							<div className="message">
								<FormattedMessage id="forms.plzort_error"></FormattedMessage>
							</div>
						)}
					</div>
					<Field
						component={InputSelect}
						name="LAND1_2"
						label="forms.land"
						placeholder="forms.please_select"
						options={values.ZWOCH === '' ? intl.messages.LANDX : intl.messages.LANDZ}
						labelField="value"
						valueField="key"
					/>
					{values.LAND1_2 === 'CH' && values.ZWOCH !== 'X' && (
						<Field
							component={InputSelect}
							name="STATE_2"
							label="forms.kanton"
							placeholder="forms.please_select"
							options={intl.messages.KANTO}
							labelField="value"
							valueField="key"
						/>
					)}
					<h3>
						<FormattedMessage
							id={values.ZWOCH === '' ? 'section.korrspndzadr' : 'section.wohn_schweiz'}
						></FormattedMessage>
					</h3>
					{values.ZWOCH === '' && (
						<div className="checkbox-group section-toggle">
							<Field
								component={InputCheckbox}
								name="INT_KORRESPOND_ABW"
								label="forms.korrespond_abw"
								checkedValue={1}
								uncheckedValue={0}
								key="abweichend"
							/>
						</div>
					)}
					{(values.INT_KORRESPOND_ABW === 1 || values.ZWOCH === 'X') && (
						<div className="section-wrap">
							<Field
								component={Input}
								type="text"
								name="STRAS_1"
								label="forms.strasse"
								placeholder="forms.strasse_placeholder"
							/>
							<div className="input-group">
								<label htmlFor="plzort">
									<FormattedMessage id="forms.plzort"></FormattedMessage>
								</label>
								<div className="input-group-plzort">
									<Field
										component={Input}
										type="text"
										name="PSTLZ_1"
										placeholder="forms.plz_placeholder"
										maxLength={10}
										hideErrors
										onChange={(e) => {
											let { value } = e.target;
											value = value.toUpperCase();
											const regex = /^[A-Z0-9\-\s]{0,10}$/;
											if (value === '' || (value.length <= 10 && regex.test(value))) {
												setFieldValue('PSTLZ_1', value);
											}
										}}
									/>
									<Field component={Input} type="text" name="ORT01_1" placeholder="forms.ort_placeholder" hideErrors />
								</div>
								{(touched.PSTLZ_1 || touched.ORT01_1) && (errors.PSTLZ_1 || errors.ORT01_1) && (
									<div className="message">
										<FormattedMessage id="forms.plzort_error"></FormattedMessage>
									</div>
								)}
							</div>
							<Field
								component={InputSelect}
								name="LAND1_1"
								label="forms.land"
								placeholder="forms.please_select"
								options={values.ZWOCH === '' ? intl.messages.LANDX : intl.messages.LANDCH}
								labelField="value"
								valueField="key"
							/>
							{values.LAND1_1 === 'CH' && (
								<Field
									component={InputSelect}
									name="STATE_1"
									label="forms.kanton"
									placeholder="forms.please_select"
									options={intl.messages.KANTO}
									labelField="value"
									valueField="key"
								/>
							)}
						</div>
					)}
					<h3>
						<FormattedMessage id="section.nationalitaet"></FormattedMessage>
					</h3>
					<Field
						component={InputSelect}
						name="NATIO"
						label="forms.staatsangeh"
						placeholder="forms.please_select"
						options={intl.messages.LANDX}
						labelField="value"
						valueField="key"
					/>
					{values.NATIO.length > 1 && values.NATIO !== 'CH' && (
						<div className="input-field">
							<Field
								component={InputSelect}
								name="ASTAT"
								label="forms.aufenhaltsbew"
								placeholder="forms.please_select"
								options={intl.messages.ASTAT}
								labelField="value"
								valueField="key"
							/>
							{values.ASTAT === 'G' && (
								<InputRadioGroup label="forms.rueckkehr" name="ZRUEC" values={values} errors={errors} touched={touched}>
									<Field
										component={InputRadio}
										name="ZRUEC"
										label="forms.rueckkehr.daily"
										value="1"
										valueFormat="string"
									/>
									<Field
										component={InputRadio}
										name="ZRUEC"
										label="forms.rueckkehr.weekly"
										value="2"
										valueFormat="string"
									/>
								</InputRadioGroup>
							)}
							{values.ASTAT !== '1' && values.ASTAT !== '' && (
								<>
									<Field
										component={Input}
										type="text"
										name="BEWNR"
										label="forms.zemis"
										placeholder="forms.zemis_placeholder"
										maxLength="20"
									/>
									<Field
										component={Input}
										type="date"
										name="ABLAD"
										label="forms.zemis_gueltig"
										placeholder="forms.datum_placeholder"
									/>
								</>
							)}
							<Field
								component={InputSelect}
								name="KONFE"
								label="forms.konfession"
								placeholder="forms.please_select"
								options={intl.messages.KONFE}
								labelField="value"
								valueField="key"
								caption="forms.konfession_caption"
							/>
						</div>
					)}
					<h3>
						<FormattedMessage id="section.notfallkontakt"></FormattedMessage>
					</h3>
					<Field
						component={Input}
						type="text"
						name="NAME2_4"
						label="forms.vorname_nachname"
						placeholder="forms.vorname_nachname_placeholder"
					/>
					<Field
						component={Input}
						type="text"
						name="PHONE_4"
						label="forms.mobiltel"
						placeholder="forms.mobiltel_placeholder"
					/>
					<Field component={Input} type="text" name="ZZBEZIEHUNG_4" label="forms.beziehung" />
					<h3>
						<FormattedMessage id="section.verbaende"></FormattedMessage>
					</h3>
					<div className="checkbox-group">
						<Field
							component={InputCheckbox}
							name="Z_SCI_ADPV"
							label="forms.verbaende"
							checkedValue=""
							uncheckedValue="X"
						/>
					</div>
					<ErrorFocus />
					<div className="button-group move-down">
						<button type="button" className="button secondary" onClick={() => navigate(-1)}>
							<FormattedMessage id="button.cancel"></FormattedMessage>
						</button>
						<button type="submit" className="button">
							<FormattedMessage id="button.save"></FormattedMessage>
						</button>
					</div>
					<div className="required">
						<FormattedMessage id="forms.required_fields"></FormattedMessage>
					</div>
					{(false || process.env.DEV) && (
						<div>
							<hr />
							<pre>{JSON.stringify(values, 0, 2)}</pre>
							<pre>{JSON.stringify(errors, 0, 2)}</pre>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);

	return (
		<Layout title="app.title" {...props}>
			<div className="content form">{renderFormik()}</div>
		</Layout>
	);
};

PersonalDetails.propTypes = {
	config: PropTypes.object,
};

export default PersonalDetails;
