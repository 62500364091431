import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const NumericStepper = (props) => {
	const [active, setActive] = useState(props.value && props.value.toString().length > 0);
	const [focus, setFocus] = useState(false);
	const { form, field, name, label } = props;

	const classes = classNames({
		'numeric-stepper': true,
		'input-field': true,
		active: !!active,
		focus: !!focus,
		error: form.touched[field.name] && form.errors[field.name],
	});

	const changeValue = (value) => {
		let intvalue = Math.min(Math.max(parseInt(value, 10), props.min), props.max);
		if (!intvalue) intvalue = 0;
		props.form.setFieldValue(props.field.name, intvalue);
		if (props.onChange) props.onChange(intvalue);
		return intvalue;
	};

	const onFocus = (event) => {
		setActive(true);
		setFocus(true);
		if (props.onFocus) {
			props.onFocus(event);
		}
	};

	const onBlur = (event) => {
		const element = event.target;
		setActive(element.value.length !== 0);
		setFocus(false);
		if (props.onBlur) {
			props.onBlur(event);
		}
	};

	const buttonPlus = (event) => {
		if (props.field.value < props.max) {
			changeValue(props.field.value + 1);
		}
		event.preventDefault();
	};

	const buttonMinus = (event) => {
		if (props.field.value > props.min) {
			changeValue(props.field.value - 1);
		}
		event.preventDefault();
	};

	return (
		<div className={classes}>
			{label ? (
				<label htmlFor={name}>
					<FormattedMessage id={label} />
				</label>
			) : null}
			<div className="numeric-stepper-wrap" onBlur={onBlur} onFocus={onFocus}>
				<button
					type="button"
					className={field.value > props.min ? 'minus' : 'minus disabled'}
					onClick={buttonMinus}
					tabIndex="-1"
				>
					-
				</button>
				<input
					{...field}
					min={props.min}
					max={props.max}
					step={1}
					type="number"
					value={field.value || 0}
					autoComplete="off"
					spellCheck="false"
					onChange={(event) => {
						changeValue(event.target.value);
					}}
					onBlur={(event) => {
						event.target.value = parseInt(event.target.value, 10);
					}}
				></input>
				<button
					type="button"
					className={field.value < props.max ? 'plus' : 'plus disabled'}
					onClick={buttonPlus}
					tabIndex="-1"
				>
					+
				</button>
			</div>
		</div>
	);
};

NumericStepper.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string,
	max: PropTypes.number,
	min: PropTypes.number,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NumericStepper;
