import axios from 'axios';
import { isEqual, findIndex, cloneDeep, isEmpty, padStart } from 'lodash';
import { calculateAge, nextStartDate } from './Utils';
import Schema from './Schema';

const personal = 1;
const partner = 2;
const children = 3;
const financial = 4;
const uploads = 5;
const documents = 6;

class Data {
	static init(data) {
		try {
			// backup
			if (!Array.isArray(data.ZKIND)) data.ZKIND = [];
			if (!Array.isArray(data.ZADRE)) data.ZADRE = [];
			data.ZKIND_LOADED = cloneDeep(data.ZKIND);
			data.ZADRE_LOADED = cloneDeep(data.ZADRE);
			data.ZPART_LOADED = cloneDeep(data.ZPART);

			// age
			if (!data.ZEINTR) {
				data.ZEINTR = nextStartDate();
			} else {
				// console.log(`data.ZEINTR is ${data.ZEINTR}`);
			}
			data.AGE = calculateAge(data.GBDAT, data.ZEINTR);

			// adressen
			const idHauptadresse = findIndex(data.ZADRE, { ANSSA: '1' });
			if (idHauptadresse === -1) data.ZADRE.push({ ANSSA: '1', STRAS: '', ORT01: '', PSTLZ: '', LAND1: '', STATE: '' });
			const idWohnadresse = findIndex(data.ZADRE, { ANSSA: '2' });
			if (idWohnadresse === -1) data.ZADRE.push({ ANSSA: '2', STRAS: '', ORT01: '', PSTLZ: '', LAND1: '', STATE: '' });
			const idPartneradresse = findIndex(data.ZADRE, { ANSSA: '9' });
			if (idPartneradresse === -1)
				data.ZADRE.push({ ANSSA: '9', STRAS: '', ORT01: '', PSTLZ: '', LAND1: '', STATE: '' });
			const idNotfallkontakt = findIndex(data.ZADRE, { ANSSA: '4' });
			if (idNotfallkontakt === -1) data.ZADRE.push({ ANSSA: '4', NAME2: '', PHONE: '', ZZBEZIEHUNG: '' });
			Data.flatten(data);

			// save states
			if (typeof data.ZGUI1 === 'undefined') data.ZGUI1 = ''; // personal
			if (typeof data.ZGUI2 === 'undefined') data.ZGUI2 = ''; // partnership
			if (typeof data.ZGUI3 === 'undefined') data.ZGUI3 = ''; // children
			if (typeof data.ZGUI4 === 'undefined') data.ZGUI4 = ''; // financial
			if (typeof data.ZGUI5 === 'undefined') data.ZGUI5 = ''; // uploads
			if (typeof data.ZGUI6 === 'undefined') data.ZGUI6 = ''; // documents
		} catch (error) {
			console.log(error);
		}

		/* tests */

		// data.ANRED = '0'; // Anrede leer
		// data.NAME2 = 'Smith'; // abweichender Geburtsname
		// data.STRAS_1 = 'Gasse 2'; // abweichende Wohndresse
		// data.STRAS_9 = 'Gasse 2'; // abweichende Wohndresse Partner
		// data.PERSK = '0'; // financial, Monatslohn 12/13 hidden
		// data.ZGSBB = 'X'; // financial, SBB Kundennummer

		// console.log('init', data);
		return data;
	}

	static flatten(data) {
		// addresses
		data.ZADRE.forEach((key, index) => {
			const element = data.ZADRE[index];
			const id = element.ANSSA;
			if (id === '4') {
				data[`ANSSA_${id}`] = element.ANSSA;
				data[`NAME2_${id}`] = element.NAME2;
				data[`PHONE_${id}`] = element.PHONE;
				data[`ZZBEZIEHUNG_${id}`] = element.ZZBEZIEHUNG;
				return;
			}
			data[`ANSSA_${id}`] = element.ANSSA;
			data[`STRAS_${id}`] = element.STRAS;
			data[`ORT01_${id}`] = element.ORT01;
			data[`PSTLZ_${id}`] = element.PSTLZ;
			data[`LAND1_${id}`] = element.LAND1;
			data[`STATE_${id}`] = element.STATE;
		});

		// partner
		Object.keys(data.ZPART).forEach((key) => {
			data[`ZPART_${key}`] = data.ZPART[key];
		});

		// uploads
		for (let index = 1; index < 19; index += 1) {
			const key = `ZDK${padStart(index, 2, '0')}`;
			data[key] = { ZDOKART: `${key}`, ZDOKU: '', ZDOKN: '' };
		}
		data.ZDK06B = { ZDOKART: 'ZDK06B', ZDOKU: '', ZDOKN: '' };
		data.ZDK11B = { ZDOKART: 'ZDK11B', ZDOKU: '', ZDOKN: '' };
		data.ZDK12B = { ZDOKART: 'ZDK12B', ZDOKU: '', ZDOKN: '' };
		data.ZDK13B = { ZDOKART: 'ZDK13B', ZDOKU: '', ZDOKN: '' };
		data.ZDOKU.forEach((key, index) => {
			data[data.ZDOKU[index].ZDOKART] = data.ZDOKU[index];
		});

		// console.log('flatten', data);
	}

	static unflattenAddress(id, data, changed) {
		let index = findIndex(data.ZADRE, (value) => value.ANSSA === id);
		if (index === -1) {
			if (id === '4') {
				data.ZADRE.push({ ANSSA: id, NAME2: '', PHONE: '', ZZBEZIEHUNG: '' });
			} else {
				data.ZADRE.push({ ANSSA: id, STRAS: '', ORT01: '', PSTLZ: '', LAND1: '', STATE: '' });
			}
			index = data.ZADRE.length - 1;
		}
		Object.keys(data.ZADRE[index]).forEach((key) => {
			data.ZADRE[index][key] = data[`${key}_${id}`];
			delete changed[`${key}_${id}`];
		});
	}

	static saveSection(section, values, data, navigate, home) {
		const previousAge = data.AGE;

		switch (section) {
			case personal:
				values.ZGUI1 = 'X';

				if (values.INT_GEBNAME_ABW === 0) {
					values.NAME2 = '';
				}
				if (values.INT_KORRESPOND_ABW === 0 && values.ZWOCH === '') {
					values.STRAS_1 = '';
					values.PSTLZ_1 = '';
					values.ORT01_1 = '';
					values.LAND1_1 = '';
					values.STATE_1 = '';
				}
				if (values.LAND1_1 !== 'CH') values.STATE_1 = '';
				if (values.LAND1_2 !== 'CH') values.STATE_2 = '';
				if (values.NATIO === 'CH') {
					values.ASTAT = '';
					values.ZRUEC = '';
					values.BEWNR = '';
					values.ABLAD = '';
					values.KONFE = '';
				}
				if (values.ASTAT !== 'G') {
					values.ZRUEC = '0';
				}
				if (values.ASTAT === '1') {
					values.BEWNR = '';
					values.ABLAD = '';
				}

				// partner seit, wenn ledig geburtsdatum
				if (data.FAMST === '0') {
					values.FAMDT = values.GBDAT;
				}

				// age change detect (Financial, SBB, age 25+)
				values.AGE = calculateAge(values.GBDAT, data.ZEINTR);
				if ((previousAge > 25 && values.AGE <= 25) || (previousAge <= 25 && values.AGE > 25)) {
					// console.log('age transition from', previousAge, 'to', values.AGE);
					values.ZFSBB = '';
				}

				break;

			case partner:
				values.ZGUI2 = 'X';
				if (values.FAMST !== '1' && values.FAMST !== '4' && values.FAMST !== '5') {
					values.ZPART_FASEX = '';
					values.ZPART_FANAM = '';
					values.ZPART_FAVOR = '';
					values.ZPART_FGBNA = '';
					values.ZPART_FGBDT = '0000-00-00';
					values.ZPART_FANAT = '';
					values.ZPART_ZAUFB = '';
					values.ZPART_ZQSTE = '';
					values.ZPART_EMPYN = '';
					values.ZPART_ZEINA = '';
					values.ZPART_ZARBG = '';
					values.ZPART_ZARBO = '';
					values.ZPART_PAYBEGDA = '0000-00-00';
					values.ZPART_PAYENDDA = '0000-00-00';
					values.ZPART_NAHVN = '';
					values.ZPART_ZERSA = '';
					values.ZPART_ZERSD = '0000-00-00';
					values.STRAS_9 = '';
					values.PSTLZ_9 = '';
					values.ORT01_9 = '';
					values.LAND1_9 = '';
					values.STATE_9 = '';
				} else {
					if (values.INT_PARTGEBNAME_ABW === 0) {
						values.ZPART_FGBNA = '';
					}
					if (values.INT_PARTNRADR_ABW === 0) {
						values.STRAS_9 = '';
						values.PSTLZ_9 = '';
						values.ORT01_9 = '';
						values.LAND1_9 = '';
					}
					values.STATE_9 = '';
					if (values.ZPART_FANAT === 'CH') {
						values.ZPART_ZAUFB = '';
						values.ZPART_ZQSTE = '';
					}
					if (values.ZPART_EMPYN !== 'X') {
						values.ZPART_ZEINA = '';
						values.ZPART_ZARBG = '';
						values.ZPART_ZARBO = '';
						values.ZPART_PAYBEGDA = '0000-00-00';
						values.ZPART_PAYENDDA = '0000-00-00';
						values.ZPART_NAHVN = '';
					}
					if (values.ZPART_ZERSA !== 'X') {
						values.ZPART_ZERSD = '0000-00-00';
					}
				}
				break;

			case children:
				values.ZGUI3 = 'X';
				if (values.INT_KINDER === 0) {
					values.ZKIZU = '';
					values.ZKIND = [];
				}
				break;

			case financial:
				values.ZGUI4 = 'X';
				if (values.ZBANK === 'O') {
					values.IBAN = '';
				}
				if (values.NBESC === '2') {
					values.TAETE = '';
					values.WOSTD = '';
				}
				if (values.ZFSBB !== '3' && values.ZFSBB !== '4') {
					values.ZFSDT = '0000-00-00';
				}
				break;

			case uploads:
				values.ZGUI5 = 'X';
				break;

			case documents:
				values.ZGUI6 = 'X';
				break;

			default:
				console.log('save: unknown section', section);
				return;
		}

		Data.valuesToData(section, values, data);
		navigate(home, { replace: true });
	}

	static valuesToData(section, values, data) {
		let changed = {};
		const deleted = [];
		// if (process.env.DEV) console.log('valuesToData', values);

		// change in place
		Object.keys(values).forEach((key) => {
			if (key.substring(0, 4) !== 'INT_' && typeof values[key] !== 'undefined') {
				if (Array.isArray(values[key]) || !isEqual(data[key], values[key])) {
					changed[key] = values[key];
				}
				data[key] = values[key];
			}
		});
		// if (process.env.DEV) console.log('valuesToData changeset', changed);

		if (!isEmpty(changed)) {
			try {
				if (section === personal) {
					Data.unflattenAddress('2', data, changed);
					Data.unflattenAddress('1', data, changed);
					Data.unflattenAddress('4', data, changed); // notfallkontakt

					// abw. wohnadresse deleted?
					const id = findIndex(data.ZADRE, (value) => value.ANSSA === '1' && value.STRAS === '');
					if (id > -1) {
						const idLoaded = findIndex(data.ZADRE_LOADED, (value) => value.ANSSA === '1' && value.STRAS !== '');
						if (idLoaded > -1) {
							deleted.push(['ZADRE', 'ANSSA', '1']);
						}
					}
				}

				if (section === partner) {
					Data.unflattenAddress('9', data, changed);

					// abw. wohnadresse partner deleted?
					const id = findIndex(data.ZADRE, (value) => value.ANSSA === '9' && value.STRAS === '');
					if (id > -1) {
						const idLoaded = findIndex(data.ZADRE_LOADED, (value) => value.ANSSA === '9' && value.STRAS !== '');
						if (idLoaded > -1) {
							deleted.push(['ZADRE', 'ANSSA', '9']);
						}
					}

					// partner data
					Object.keys(data.ZPART).forEach((key) => {
						data.ZPART[key] = data[`ZPART_${key}`];
						delete changed[`ZPART_${key}`];
					});
					if (!isEqual(data.ZPART, data.ZPART_LOADED)) {
						changed.ZPART = data.ZPART;
						data.ZPART_LOADED = cloneDeep(data.ZPART);
					}
				}

				// adresses changed?
				if (section === personal || section === partner) {
					data.ZADRE = data.ZADRE.filter((item) => item.STRAS !== '');
					if (!isEqual(data.ZADRE, data.ZADRE_LOADED)) {
						changed.ZADRE = data.ZADRE;
						data.ZADRE_LOADED = cloneDeep(data.ZADRE);
					}
				}

				if (section === children) {
					// deleted
					if (data.ZKIND.length < data.ZKIND_LOADED.length) {
						for (let index = data.ZKIND.length; index < data.ZKIND_LOADED.length; index += 1) {
							deleted.push(['ZKIND', 'ZKINR', `${index + 1}`]);
						}
					}
					if (changed.ZKIND.length === 0) delete changed.ZKIND;
					data.ZKIND_LOADED = cloneDeep(data.ZKIND);
				}
			} catch (error) {
				console.log(error);
			}

			// number to strings, just to be safe
			const replacer = (key, value) => {
				if (typeof value === 'number') {
					return value.toString();
				}
				return value;
			};
			changed = JSON.parse(JSON.stringify(changed, replacer));

			// if (process.env.DEV) console.log({ changed, deleted });
			if (!isEmpty(changed) || !isEmpty(deleted)) {
				axios.post(`${process.env.APP_SERVER}`, { action: 'save', changed, deleted }).then((result) => {
					// if (process.env.DEV) console.log('save', result.data);
				});
			}
		}

		// console.log(data);
	}

	static dataToValues(section, data) {
		let result = {};

		switch (section) {
			case personal:
				return {
					ANRED: data.ANRED, // Anrede
					VORNA: data.VORNA, // Vorname
					NACHN: data.NACHN, // Nachname

					NAME2: data.NAME2, // Geburtsname
					GBDAT: data.GBDAT, // Geburtsdatum

					ZWOCH: data.ZWOCH, // Wochenaufenhalter

					STRAS_2: data.STRAS_2, // Wohnadresse
					PSTLZ_2: data.PSTLZ_2,
					ORT01_2: data.ORT01_2,
					LAND1_2: data.LAND1_2,
					STATE_2: data.STATE_2,

					STRAS_1: data.STRAS_1, // Korresponenzadresse
					PSTLZ_1: data.PSTLZ_1,
					ORT01_1: data.ORT01_1,
					LAND1_1: data.LAND1_1,
					STATE_1: data.STATE_1,

					NATIO: data.NATIO, // Nationalität
					ASTAT: data.ASTAT, // Aufenthaltsbewilligung Typ
					ZRUEC: data.ZRUEC, // Wann kehrst du zurück? Grenzgänger
					BEWNR: data.BEWNR, // Zemis-Nummer
					ABLAD: data.ABLAD, // Aufenthaltsbewilligung gültig bis
					KONFE: data.KONFE, // Religion bzw. Konfession

					NAME2_4: data.NAME2_4,
					PHONE_4: data.PHONE_4,
					ZZBEZIEHUNG_4: data.ZZBEZIEHUNG_4,

					Z_SCI_ADPV: data.Z_SCI_ADPV, // Personalverbände

					INT_GEBNAME_ABW: data.NAME2 ? 1 : 0, // Geburtsname abweichend
					INT_KORRESPOND_ABW: data.STRAS_1 && !data.ZWOCH ? 1 : 0, // Korrspondenzandresse abweichend
				};

			case partner:
				return {
					FAMST: data.FAMST, // Familienstand
					FAMDT: data.FAMDT, // Zivilstand seit
					ZGEDT: data.ZGEDT, // Getrennt seit

					ZPART_FASEX: data.ZPART_FASEX, // Anrede
					ZPART_FANAM: data.ZPART_FANAM, // Nachname
					ZPART_FAVOR: data.ZPART_FAVOR, // Vorname
					ZPART_FGBNA: data.ZPART_FGBNA, // Geburtsname
					ZPART_FGBDT: data.ZPART_FGBDT, // Geburtsdatum
					ZPART_FANAT: data.ZPART_FANAT, // Nationalität
					ZPART_ZAUFB: data.ZPART_ZAUFB, // Aufenthaltsbewilligung
					ZPART_ZQSTE: data.ZPART_ZQSTE, // Unterliegt dein Partner/deine Partnerin der Quellensteuerpflicht?
					ZPART_EMPYN: data.ZPART_EMPYN, // Partner geht einer Beschäftigung nach
					ZPART_ZEINA: data.ZPART_ZEINA, // Einkommensart
					ZPART_ZARBG: data.ZPART_ZARBG, // Arbeitgeber
					ZPART_ZARBO: data.ZPART_ZARBO, // Arbeitsort
					ZPART_PAYBEGDA: data.ZPART_PAYBEGDA, // Beginn der Tätigkeit
					ZPART_PAYENDDA: data.ZPART_PAYENDDA, // Ende der Tätigkeit
					ZPART_NAHVN: data.ZPART_NAHVN, // Sozialversicherungsnummer
					ZPART_ZERSA: data.ZPART_ZERSA, // Ersatzeinkommen
					ZPART_ZERSD: data.ZPART_ZERSD, // Ersatzeinkommen seit

					STRAS_9: data.STRAS_9,
					PSTLZ_9: data.PSTLZ_9,
					ORT01_9: data.ORT01_9,
					LAND1_9: data.LAND1_9,
					STATE_9: '',

					INT_PARTGEBNAME_ABW: data.ZPART_FGBNA ? 1 : 0, // Geburtsname abweichend
					INT_PARTNRADR_ABW: data.STRAS_9 ? 1 : 0, // Wohnandresse abweichend
				};

			case children:
				return {
					ZKIND: data.ZKIND, // Kinder
					ZKIZU: data.ZKIZU, // Kinderzulagen
					INT_KINDER: data.ZKIND.length, // Anzahl Kinder
				};

			case financial:
				return {
					NAHVN: data.NAHVN, // Sozialversicherungsnummer 756.0081.3782.50 756.5997.3623.31
					ZANZL: data.ZANZL, // Auszahlung des Basislohns
					ZBANK: data.ZBANK, // Schweizer Bankkonto?
					IBAN: data.IBAN, // IBAN CH43 0023 5235 2301 5240 A
					ZGSBB: data.ZGSBB, // 1. Klasse GA -> logic
					PERSK: data.PERSK, // Mitarbeiterkreis -> logic
					LOBNR: data.LOBNR, // SBB Kundennummer
					ZFSBB: data.ZFSBB, // Vergütung/Fahrausweis der SBB
					ZFSDT: data.ZFSDT, // Railcheck gueltig
					NBESC: data.NBESC, // Nebenerwerb?
					TAETE: data.TAETE, // Bezeichnung  Nebenerwerb
					WOSTD: data.WOSTD, // Stunden Nebenerwerb
					ZEINTR: data.ZEINTR, // Eintrittsdatum (f. Railcheck)
				};

			case uploads:
				result = {
					ZVERP: data.ZVERP, // Vertrag per Post
					ZVERD: data.ZVERD, // Datum Vertragsrücksendung
				};
				for (let index = 1; index < 19; index += 1) {
					const key = `ZDK${padStart(index, 2, '0')}`;
					result[key] = data[key];
				}
				result.ZDK06B = data.ZDK06B;
				result.ZDK11B = data.ZDK11B;
				result.ZDK12B = data.ZDK12B;
				result.ZDK13B = data.ZDK13B;
				return result;

			case documents:
				return {
					ZCONF: data.ZCONF, // Dokumente gelesen
				};

			default:
				console.log('Data.dataToValues: unknown section', section);
				return {};
		}
	}

	static validateSections(data) {
		return {
			personalDetailsIsValid: Data.validateSection(Data.personal, data),
			partnerIsValid: Data.validateSection(Data.partner, data),
			childrenIsValid: Data.validateSection(Data.children, data),
			financialIsValid: Data.validateSection(Data.financial, data),
			uploadsIsValid: Data.validateSection(Data.uploads, data),
			documentsIsValid: Data.validateSection(Data.documents, data),
		};
	}

	static validateSection(section, data) {
		let isValid = !!data[`ZGUI${section}`];
		try {
			Schema.load(section, data).validateSync(Data.dataToValues(section, data));
		} catch (err) {
			// console.log(err);
			isValid = false;
		}
		return isValid;
	}

	static areAllSectionsValid(state) {
		return (
			state.personalDetailsIsValid &&
			state.partnerIsValid &&
			state.childrenIsValid &&
			state.financialIsValid &&
			state.uploadsIsValid &&
			state.documentsIsValid
		);
	}

	static get personal() {
		return personal;
	}

	static get partner() {
		return partner;
	}

	static get children() {
		return children;
	}

	static get financial() {
		return financial;
	}

	static get uploads() {
		return uploads;
	}

	static get documents() {
		return documents;
	}
}

export default Data;
