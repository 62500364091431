/* eslint-disable react/jsx-no-useless-fragment */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Layout from './Layout';
import Input from '../components/Input';
import InputRadio from '../components/InputRadio';
import InputRadioGroup from '../components/InputRadioGroup';
import InputCheckbox from '../components/InputCheckbox';
import InputSelect from '../components/InputSelect';
import ErrorFocus from '../components/ErrorFocus';
import Data from '../modules/Data';
import Schema from '../modules/Schema';

const Partnership = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const formikFormRef = useRef();
	const schema = Schema.load(Schema.partner, props.config.data);
	const initialValues = schema.cast(Data.dataToValues(Data.partner, props.config.data));

	const saveForm = (values) => {
		const { data, home } = props.config;
		Data.saveSection(Data.partner, values, data, navigate, home);
	};

	const renderFormik = () => (
		<Formik
			isInitialValid
			initialValues={initialValues}
			validationSchema={() => Yup.lazy((values) => Schema.load(Schema.partner, values))}
			innerRef={formikFormRef}
			onSubmit={saveForm}
		>
			{({ values, errors, touched, setFieldValue }) => (
				<Form>
					<div className="form-header">
						<h1 className="smaller">
							<FormattedMessage id="home.partner"></FormattedMessage>
						</h1>
						<button onClick={() => navigate(-1)} type="button" className="close-button">
							&nbsp;
						</button>
					</div>
					<h3>
						<FormattedMessage id="section.zivilstand"></FormattedMessage>
					</h3>
					<Field
						component={InputSelect}
						name="FAMST"
						label="forms.zivilstand"
						placeholder="forms.please_select"
						options={intl.messages.FAMST}
						labelField="value"
						valueField="key"
					/>
					{values.FAMST !== '' && values.FAMST !== '0' && (
						<>
							{values.FAMST === '4' ? (
								<>
									<Field
										component={Input}
										type="date"
										name="ZGEDT"
										label="forms.zivilstand_seit"
										placeholder="forms.datum_placeholder"
									/>
									<Field
										component={Input}
										type="date"
										name="FAMDT"
										label="forms.zivilstand_verheiratet_seit"
										placeholder="forms.datum_placeholder"
										caption="forms.zivilstand_seit_caption"
									/>
								</>
							) : (
								<Field
									component={Input}
									type="date"
									name="FAMDT"
									label="forms.zivilstand_seit"
									placeholder="forms.datum_placeholder"
									caption="forms.zivilstand_seit_caption"
								/>
							)}
						</>
					)}
					{(values.FAMST === '1' || values.FAMST === '4' || values.FAMST === '5') && (
						<div className="section-wrap">
							<h3>
								<FormattedMessage id="section.partnerperso"></FormattedMessage>
							</h3>
							<InputRadioGroup
								label="forms.anrede"
								name="ZPART_FASEX"
								values={values}
								errors={errors}
								touched={touched}
							>
								{intl.messages.ANRED.map((object) => (
									<Field
										component={InputRadio}
										name="ZPART_FASEX"
										label="forms.missing"
										labelI18n={object.value}
										value={object.key}
										valueFormat="string"
										key={object.key}
									/>
								))}
							</InputRadioGroup>
							<Field
								component={Input}
								type="text"
								name="ZPART_FAVOR"
								label="forms.vorname"
								caption="forms.legalname"
								placeholder="forms.vorname_placeholder"
							/>
							<Field
								component={Input}
								type="text"
								name="ZPART_FANAM"
								label="forms.nachname"
								caption="forms.legalname"
								placeholder="forms.nachname_placeholder"
							/>
							<div className="checkbox-group">
								<Field
									component={InputCheckbox}
									name="INT_PARTGEBNAME_ABW"
									label="forms.geburtsname_abw"
									checkedValue={1}
									uncheckedValue={0}
									onChange={() => {
										if (values.INT_PARTGEBNAME_ABW === 0)
											setTimeout(() => document.querySelector(`input[name=ZPART_FGBNA]`).focus(), 5);
									}}
								/>
							</div>
							{values.INT_PARTGEBNAME_ABW === 1 && (
								<Field
									component={Input}
									type="text"
									name="ZPART_FGBNA"
									label="forms.geburtsname"
									placeholder="forms.geburtsname_placeholder"
								/>
							)}
							<Field
								component={Input}
								type="date"
								name="ZPART_FGBDT"
								label="forms.geburtsdatum"
								placeholder="forms.datum_placeholder"
							/>
							<h3>
								<FormattedMessage id="section.wohnadr"></FormattedMessage>
							</h3>
							<div className="checkbox-group section-toggle">
								<Field
									component={InputCheckbox}
									name="INT_PARTNRADR_ABW"
									label="forms.wohnadr_abw"
									checkedValue={1}
									uncheckedValue={0}
								/>
							</div>
							{values.INT_PARTNRADR_ABW === 1 && (
								<div className="section-wrap">
									<Field
										component={Input}
										type="text"
										name="STRAS_9"
										label="forms.strasse"
										placeholder="forms.strasse_placeholder"
									/>
									<div className="input-group">
										<label htmlFor="plzort">
											<FormattedMessage id="forms.plzort"></FormattedMessage>
										</label>
										<div className="input-group-plzort">
											<Field
												component={Input}
												type="text"
												name="PSTLZ_9"
												placeholder="forms.plz_placeholder"
												maxLength={10}
												hideErrors
												onChange={(e) => {
													let { value } = e.target;
													value = value.toUpperCase();
													const regex = /^[A-Z0-9\-\s]{0,10}$/;
													if (value === '' || (value.length <= 10 && regex.test(value))) {
														setFieldValue('PSTLZ_9', value);
													}
												}}
											/>
											<Field
												component={Input}
												type="text"
												name="ORT01_9"
												placeholder="forms.ort_placeholder"
												hideErrors
											/>
										</div>
										{(touched.PSTLZ_9 || touched.ORT01_9) && (errors.PSTLZ_9 || errors.ORT01_9) && (
											<div className="message">
												<FormattedMessage id="forms.plzort_error"></FormattedMessage>
											</div>
										)}
									</div>
									<Field
										component={InputSelect}
										name="LAND1_9"
										label="forms.land"
										placeholder="forms.please_select"
										options={intl.messages.LANDX}
										labelField="value"
										valueField="key"
									/>
									<Field type="hidden" name="STATE_9" value="" />
								</div>
							)}
							<h3>
								<FormattedMessage id="section.weitere"></FormattedMessage>
							</h3>
							<Field
								component={InputSelect}
								name="ZPART_FANAT"
								label="forms.nationalitaet_p"
								placeholder="forms.please_select"
								options={intl.messages.LANDX}
								labelField="value"
								valueField="key"
							/>
							{values.ZPART_FANAT.length > 1 && values.ZPART_FANAT !== 'CH' && (
								<>
									<div className="input-field">
										<Field
											component={InputSelect}
											name="ZPART_ZAUFB"
											label="forms.aufenhaltsbew"
											placeholder="forms.please_select"
											options={intl.messages.ASTAT}
											labelField="value"
											valueField="key"
										/>
									</div>
									<h3>
										<FormattedMessage id="section.quellensteuer"></FormattedMessage>
									</h3>
									<InputRadioGroup
										label="forms.quellensteuer"
										name="ZPART_ZQSTE"
										values={values}
										errors={errors}
										touched={touched}
									>
										<Field
											component={InputRadio}
											name="ZPART_ZQSTE"
											label="forms.label.yes"
											value="X"
											valueFormat="string"
										/>
										<Field
											component={InputRadio}
											name="ZPART_ZQSTE"
											label="forms.label.no"
											value="O"
											valueFormat="string"
										/>
									</InputRadioGroup>
								</>
							)}
							<h3>
								<FormattedMessage id="section.beschaeftigung"></FormattedMessage>
							</h3>
							<InputRadioGroup
								label="forms.beschaeftigung"
								name="ZPART_EMPYN"
								values={values}
								errors={errors}
								touched={touched}
							>
								<Field
									component={InputRadio}
									name="ZPART_EMPYN"
									label="forms.label.yes"
									value="X"
									valueFormat="string"
								/>
								<Field
									component={InputRadio}
									name="ZPART_EMPYN"
									label="forms.label.no"
									value="O"
									valueFormat="string"
								/>
							</InputRadioGroup>
							{values.ZPART_EMPYN === 'X' && (
								<div className="input-field">
									<Field component={Input} type="text" name="ZPART_ZARBG" label="forms.arbeitgeber" />
									<Field component={Input} type="text" name="ZPART_ZARBO" label="forms.arbeitsort" />
									<InputRadioGroup
										label="forms.erwerbsart"
										name="ZPART_ZEINA"
										values={values}
										errors={errors}
										touched={touched}
									>
										{intl.messages.ZEINA.map((object) => (
											<Field
												component={InputRadio}
												name="ZPART_ZEINA"
												label="forms.missing"
												labelI18n={object.value}
												value={object.key}
												valueFormat="string"
												key={object.key}
											/>
										))}
									</InputRadioGroup>
									<Field
										component={Input}
										type="date"
										name="ZPART_PAYBEGDA"
										label="forms.beginn_taet"
										placeholder="forms.datum_placeholder"
									/>
									<Field
										component={Input}
										type="date"
										name="ZPART_PAYENDDA"
										label="forms.ende_taet"
										placeholder="forms.datum_placeholder"
									/>
									<Field
										component={Input}
										type="text"
										name="ZPART_NAHVN"
										label="forms.sozvrsnr_partner"
										placeholder="forms.sozversnummer_placeholder"
										mask={{ numericOnly: true, blocks: [3, 4, 4, 2], delimiter: '.' }}
									/>
								</div>
							)}
							<h3>
								<FormattedMessage id="section.ersatzeinkommen"></FormattedMessage>
							</h3>
							<InputRadioGroup
								label="forms.ersatzeinkommen"
								name="ZPART_ZERSA"
								values={values}
								errors={errors}
								touched={touched}
							>
								<Field
									component={InputRadio}
									name="ZPART_ZERSA"
									label="forms.label.yes"
									value="X"
									valueFormat="string"
								/>
								<Field
									component={InputRadio}
									name="ZPART_ZERSA"
									label="forms.label.no"
									value="O"
									valueFormat="string"
								/>
							</InputRadioGroup>
							{values.ZPART_ZERSA === 'X' && (
								<Field
									component={Input}
									type="date"
									name="ZPART_ZERSD"
									label="forms.ersatz_seit"
									placeholder="forms.datum_placeholder"
								/>
							)}
						</div>
					)}
					<ErrorFocus />
					<div className="button-group move-down">
						<button type="button" className="button secondary" onClick={() => navigate(-1)}>
							<FormattedMessage id="button.cancel"></FormattedMessage>
						</button>
						<button type="submit" className="button">
							<FormattedMessage id="button.save"></FormattedMessage>
						</button>
					</div>
					<div className="required">
						<FormattedMessage id="forms.required_fields"></FormattedMessage>
					</div>
					{true && process.env.DEV && (
						<div>
							<hr />
							<pre>{JSON.stringify(values, 0, 2)}</pre>
							<pre>{JSON.stringify(errors, 0, 2)}</pre>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);

	return (
		<Layout title="app.title" {...props}>
			<div className="content form">{renderFormik()}</div>
		</Layout>
	);
};

Partnership.propTypes = {
	config: PropTypes.object,
};

export default Partnership;
