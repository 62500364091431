import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import Layout from './Layout';
import Input from '../components/Input';
import InputRadio from '../components/InputRadio';
import InputRadioGroup from '../components/InputRadioGroup';
import InputCheckbox from '../components/InputCheckbox';
import InputSelect from '../components/InputSelect';
import NumericStepper from '../components/NumericStepper';
import ErrorFocus from '../components/ErrorFocus';
import Data from '../modules/Data';
import Schema from '../modules/Schema';

const Children = (props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const formikFormRef = useRef();
	const schema = Schema.load(Schema.children, props.config.data);
	const initialValues = schema.cast(Data.dataToValues(Data.children, props.config.data));

	const saveForm = (values) => {
		const { data, home } = props.config;
		Data.saveSection(Data.children, values, data, navigate, home);
	};

	const updateChildrenCount = (value) => {
		if (!formikFormRef.current) return;
		const { values } = formikFormRef.current;
		if (value < initialValues.INT_KINDER) {
			values.ZKIND.pop();
		} else {
			values.ZKIND.push({
				ZKINR: `${value}`,
				FASEX: '',
				FAVOR: '',
				FANAM: '',
				FGBDT: '',
				ZINCH: '',
				KDUTB: '',
				KDSVH: '',
				NAHVN: '',
			});
		}
		initialValues.INT_KINDER = value;
	};

	const renderFormik = () => (
		<Formik
			isInitialValid
			initialValues={initialValues}
			validationSchema={schema}
			innerRef={formikFormRef}
			onSubmit={saveForm}
		>
			{({ values, touched, errors }) => (
				<Form noValidate>
					<div className="form-header">
						<h1 className="smaller">
							<FormattedMessage id="home.children"></FormattedMessage>
						</h1>
						<button onClick={() => navigate(-1)} type="button" className="close-button">
							&nbsp;
						</button>
					</div>
					<h3>
						<FormattedMessage id="section.unterhaltspflichtig"></FormattedMessage>
					</h3>
					<Field
						onChange={(value) => updateChildrenCount(value)}
						component={NumericStepper}
						name="INT_KINDER"
						min={0}
						max={9}
						label="forms.anzahlkinder"
					/>
					{renderChildren(values, touched, errors)}
					{values.INT_KINDER > 0 && (
						<div>
							<h3>
								<FormattedMessage id="section.familienzulagen"></FormattedMessage>
							</h3>
							<div className="checkbox-group">
								<Field
									component={InputCheckbox}
									name="ZKIZU"
									label="forms.antragfamzulagen"
									checkedValue="X"
									uncheckedValue=""
								/>
							</div>
						</div>
					)}
					<ErrorFocus />
					<div className="button-group move-down">
						<button type="button" className="button secondary" onClick={() => navigate(-1)}>
							<FormattedMessage id="button.cancel"></FormattedMessage>
						</button>
						<button type="submit" className="button">
							<FormattedMessage id="button.save"></FormattedMessage>
						</button>
					</div>
					<div className="required">
						<FormattedMessage id="forms.required_fields"></FormattedMessage>
					</div>
					{true && process.env.DEV && (
						<div>
							<hr />
							<pre>{JSON.stringify(values, 0, 2)}</pre>
							<pre>{JSON.stringify(errors, 0, 2)}</pre>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);

	const renderChildren = (values, touched, errors) => (
		<FieldArray
			name="children"
			render={() => (
				<div>
					{values.ZKIND &&
						values.ZKIND.length > 0 &&
						values.ZKIND.map((child, index) => (
							<div key={index}>
								<h3>
									<FormattedMessage id="section.kind" values={{ id: index + 1 }} />
								</h3>
								<input type="hidden" name={`ZKIND.${index}.ZKINR`} value={child.ZKINR} />
								<Field
									component={Input}
									type="text"
									name={`ZKIND.${index}.FAVOR`}
									label="forms.vorname"
									caption="forms.legalname"
									placeholder="forms.vorname_placeholder"
								/>
								<Field
									component={Input}
									type="text"
									name={`ZKIND.${index}.FANAM`}
									label="forms.nachname"
									caption="forms.legalname"
									placeholder="forms.nachname_placeholder"
								/>
								<InputRadioGroup
									label="forms.geschlecht"
									name={`ZKIND.${index}.FASEX`}
									values={values}
									errors={errors}
									touched={touched}
								>
									{intl.messages.GESCH.map((object) => (
										<Field
											component={InputRadio}
											name={`ZKIND.${index}.FASEX`}
											label="forms.missing"
											labelI18n={object.value}
											value={object.key}
											valueFormat="string"
											key={object.key}
										/>
									))}
								</InputRadioGroup>
								<Field
									component={Input}
									type="date"
									name={`ZKIND.${index}.FGBDT`}
									label="forms.geburtsdatum"
									placeholder="forms.datum_placeholder"
								/>
								<InputRadioGroup
									label="forms.kind_in_ch"
									name={`ZKIND.${index}.ZINCH`}
									values={values}
									errors={errors}
									touched={touched}
								>
									<Field
										component={InputRadio}
										name={`ZKIND.${index}.ZINCH`}
										label="forms.label.yes"
										value="X"
										valueFormat="string"
									/>
									<Field
										component={InputRadio}
										name={`ZKIND.${index}.ZINCH`}
										label="forms.label.no"
										value="O"
										valueFormat="string"
									/>
								</InputRadioGroup>
								{child.ZINCH === 'X' && (
									<Field
										component={Input}
										type="number"
										name={`ZKIND.${index}.NAHVN`}
										label="forms.sozversnr_kind"
										placeholder="forms.sozversnr_kind_placeholder"
										mask={{ numericOnly: true, blocks: [3, 4, 4, 2], delimiter: '.' }}
									/>
								)}
								<Field
									component={InputSelect}
									name={`ZKIND.${index}.KDUTB`}
									label="forms.unterbringung"
									placeholder="forms.please_select"
									options={intl.messages.KDUTB}
									labelField="value"
									valueField="key"
								/>
								<Field
									component={InputSelect}
									name={`ZKIND.${index}.KDSVH`}
									label="forms.kindsverhaeltnis"
									placeholder="forms.please_select"
									options={intl.messages.KDSVH}
									labelField="value"
									valueField="key"
								/>
							</div>
						))}
				</div>
			)}
		/>
	);

	return (
		<Layout title="app.title" {...props}>
			<div className="content form">{renderFormik()}</div>
		</Layout>
	);
};

Children.propTypes = {
	config: PropTypes.object,
};

export default Children;
