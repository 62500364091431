import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Layout from './Layout';

const Maintenance = ({ config, onConfigUpdate }) => {
	const intl = useIntl();

	const createMarkup = (message) => ({
		__html: intl.formatMessage({
			id: message,
		}),
	});

	return (
		<Layout title="app.title" config={config} onConfigUpdate={onConfigUpdate}>
			<div className="content">
				<div className="content-inner">
					<h1 className="smaller">
						<FormattedMessage id="app.maintenance.title"></FormattedMessage>
					</h1>
					<p dangerouslySetInnerHTML={createMarkup('app.maintenance.text')}></p>
					<p dangerouslySetInnerHTML={createMarkup('app.maintenance.reload')}></p>
				</div>
			</div>
		</Layout>
	);
};

Maintenance.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Maintenance;
