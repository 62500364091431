import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';

class ModalAlert extends Component {
	getParent = () => document.querySelector('#app');

	render() {
		return (
			<Modal
				parentSelector={this.getParent}
				isOpen={this.props.isOpen}
				onRequestClose={this.props.handleAlertClose}
				shouldCloseOnOverlayClick
				shouldCloseOnEsc
				className="modal alert"
				overlayClassName="overlay"
				portalClassName="modal-container"
				contentLabel="Alert dialog"
				ariaHideApp={false}
				closeTimeoutMS={500}
			>
				<h3>
					<FormattedMessage id={this.props.title} />
				</h3>
				<p>
					<FormattedMessage id={this.props.text} />
				</p>
				<button type="button" onClick={this.props.handleAlertClose} className="modal-close">
					&nbsp;
				</button>
			</Modal>
		);
	}
}

ModalAlert.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleAlertClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
};

export default ModalAlert;
