import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Layout from './Layout';
import Video from '../components/Video';

const Logout = ({ config, onConfigUpdate }) => (
	<Layout title="app.title" config={config} onConfigUpdate={onConfigUpdate}>
		<div className="content video">
			<div className="content-inner">
				<h1 className="smaller">
					<FormattedMessage id="logout.title"></FormattedMessage>
				</h1>
				<p>
					<FormattedMessage id="logout.text1"></FormattedMessage>
				</p>
				<p>
					<FormattedMessage id="logout.text2"></FormattedMessage>
				</p>
				<Video
					src="video.bereit.src"
					image="video.bereit.image"
					textTitle="video.ready"
					textPlay="video.show_movie"
				></Video>
			</div>
		</div>
	</Layout>
);

Logout.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Logout;
