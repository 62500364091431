import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';

class ModalConfirm extends Component {
	getParent = () => document.querySelector('#app');

	render() {
		return (
			<Modal
				parentSelector={this.getParent}
				isOpen={this.props.isOpen}
				onRequestClose={this.props.handleConfirmCancel}
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={!this.props.isLoading}
				className="modal dialog"
				overlayClassName="overlay"
				portalClassName="modal-container"
				contentLabel="Confirmation dialog"
				ariaHideApp={false}
				closeTimeoutMS={500}
			>
				<h3>
					<FormattedMessage id={this.props.title} />
				</h3>
				<p>
					<FormattedMessage id={this.props.text} />
				</p>
				<div className="button-group align-bottom">
					<button
						className={this.props.isLoading ? 'button spinner' : 'button'}
						type="button"
						onClick={this.props.handleConfirmOk}
					>
						<FormattedMessage id="button.submit" />
						<div className="loader-spinner--small"></div>
					</button>
					<button
						className={this.props.isLoading ? 'button secondary disabled' : 'button secondary'}
						type="button"
						onClick={this.props.handleConfirmCancel}
					>
						<FormattedMessage id="button.cancel" />
					</button>
				</div>
				<button
					type="button"
					onClick={this.props.handleConfirmCancel}
					className={this.props.isLoading ? 'modal-close disabled' : 'modal-close'}
				>
					&nbsp;
				</button>
			</Modal>
		);
	}
}

ModalConfirm.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleConfirmOk: PropTypes.func.isRequired,
	handleConfirmCancel: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default ModalConfirm;
